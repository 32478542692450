/* eslint-disable no-loop-func */
import { Costants } from "./Costants";

export const QueryAdvSearch = (filtersQuery) => {

  const arrayMade = (array, type, operator) => {
      // console.log("array", array);

    let stringForQuery = "";
    let op = operator ? operator : ",";
    let divisor = "'";
    let arrayStructure = type === "geographic" ? "en" : type === "country"
      ? "country_ISO3_Code"
      : "";
    for (var i = 0; i < array.length; i++) {
      if (arrayStructure !== "") {
        if (i === array.length - 1) {
          if (arrayStructure === "country_ISO3_Code") {
            stringForQuery += `${divisor}${array[i][arrayStructure]}${divisor}`;
          }else {
            stringForQuery += `${array[i][arrayStructure]}`;
          }
        } else {
          if (arrayStructure === "country_ISO3_Code") {
            stringForQuery += `${divisor}${array[i][arrayStructure]}${divisor} ${op} `;
          } else {
            stringForQuery += `${array[i][arrayStructure]} ${op} `;
          }
        }
      } else {
        if (i === array.length - 1) {
          stringForQuery += `${array[i]}`;
        } else {
          stringForQuery += `${array[i]}${op}`;
        }
      }
    }

    // console.log("stringForQuery", stringForQuery);

    return stringForQuery;
  };

  const queryMade = (queryOption) => {
    // console.log("queryOption", queryOption);
    let querymade = "";
    for (let el of Costants.mapQuery) {
      if (
        // el !== "topic" &&
        el !== "typeoftext" &&
        el !== "record" &&
        el !== "subjectselection" &&
        queryOption[el]
      ) {
        querymade += `${el}:(${queryOption[el]}) `;
      } else if (el === "record") {
        querymade += `${queryOption[el]} `;
      } else if (
        // (el === "topic" && queryOption[el]) ||
        (el === "subjectselection" && queryOption[el]) ||
        (el === "typeoftext" && queryOption[el])
      ) {
        if (!queryOption[el].includes(",")) {
          querymade += `${el}:(${queryOption[el]}) `;
        } else {
          const selArraySplit = queryOption[el].split(",");
          selArraySplit.forEach((tpc, index) => {
            if (index === selArraySplit.length - 1) {
              querymade += `${el}:(${tpc})`;
            } else {
              querymade += `${el}:(${tpc}) OR `;
            }
          });
        }
      }
    }
    return querymade;
  };

  const facetsMade = (facets) => {
    let facetmade = "";

    for (let el of Costants.facetsMap) {
      for (let ft of facets) {
        if (ft.typeLoad === el.field) {
          facetmade += `${el.field}:(${ft.name.split("#")[0]}) `;
        }
      }
    }

    return facetmade;
  };

  const yearsQueryMade = (yearsOption) => {
    let yearsMade = "";

    if (yearsOption.yearfrom && yearsOption.yearto) {
      yearsMade += `(${Costants.yearFromMap[0]}:${yearsOption.yearfrom} ${Costants.yearToMap[0]}:${yearsOption.yearto}) | `;
      yearsMade += `(${Costants.yearFromMap[1]}:${yearsOption.yearfrom} ${Costants.yearToMap[1]}:${yearsOption.yearto})`;
    } else if (yearsOption.yearfrom && !yearsOption.yearto) {
      yearsMade += `(${Costants.yearFromMap[0]}:${yearsOption.yearfrom}) | (${Costants.yearFromMap[1]}:${yearsOption.yearfrom})`;
    } else if (!yearsOption.yearfrom && yearsOption.yearto) {
      yearsMade += `(${Costants.yearToMap[0]}:${yearsOption.yearto}) | (${Costants.yearToMap[1]}:${yearsOption.yearto})`;
    }

    return yearsMade;
  };

    
  const createQueryString = (array, operator) => {
    let madeString = "";
    if (array.length > 1) {
      for (let i = 0; i < array.length -1; i++) {
        if (i === 0) {
          madeString += array[i] + " " + operator + " " + array[i + 1];
        } else {
          madeString += " " + operator + " " + array[i + 1];
        } 
      }
    } else {
      madeString = array[0];
    }
    return madeString;
  }


  const queryBuild = () => {
    // console.log("filtersQuery", filtersQuery);

    if (filtersQuery) {
      var wordsfromrecords = filtersQuery.record;
      var wordsfromtitle = filtersQuery.title.replace(
        " ",
        ` ${filtersQuery.operatorTitle} `
      );
      // var topic = filtersQuery.topic;
      var subjectSelection = arrayMade(filtersQuery.primarySubjects);
      var type = filtersQuery.type;
      // let country_escape = "\"" + filtersQuery.country + "\""
      // console.log("country_escape", country_escape);
      var country = arrayMade(
        filtersQuery.country,
        "country",
        filtersQuery.operatorCountry
      );

      var temp_territorial = filtersQuery.geographic.map((el) => { return el.ValID });
      var territorial = createQueryString(temp_territorial, filtersQuery.operatorTerritorial);

      var temp_regional = []

      switch (filtersQuery.lang) {

        case "fr":
          temp_regional = filtersQuery.regional.map((el) => { return el.country_fr });
          break;

        case "es":
          temp_regional = filtersQuery.regional.map((el) => { return el.country_es });
          break;

        case "ar":
          temp_regional = filtersQuery.regional.map((el) => { return el.country_ar });
          break;

        case "zh":
          temp_regional = filtersQuery.regional.map((el) => { return el.country_zh });
          break;

        case "ru":
          temp_regional = filtersQuery.regional.map((el) => { return el.country_ru });
          break;

        default:
          temp_regional = filtersQuery.regional.map((el) => { return el.country_en });
          break;
      }

      var regional = createQueryString(temp_regional, filtersQuery.operatorRegional);

      
      var temp_related = filtersQuery.related.map((el) => { return el.ValID });
      var related = createQueryString(temp_related, filtersQuery.operatorRelated); 


      var temp_keywords = filtersQuery.keywords.map((el) => { return el.Code });
      var keywords = createQueryString(temp_keywords, filtersQuery.operatorKeywords);

      // var territorial = arrayMade(
      //   filtersQuery.geographic,
      //   "geographic",
      //   filtersQuery.operatorTerritorial
      // );
      
      var excluderepealed = filtersQuery.repealed ? "'REPEALED'" : "";
    }

    // console.log("country", country);

    return `{
      "${Costants.mapQuery[0]}": "${wordsfromrecords ? wordsfromrecords : ""}",
      "${Costants.mapQuery[1]}": "${wordsfromtitle ? wordsfromtitle : ""}",
      "${Costants.mapQuery[3]}": "${subjectSelection ? subjectSelection : ""}",
      "${Costants.mapQuery[4]}": "${country ? country : ""}",
      "${Costants.mapQuery[5]}": "${territorial ? territorial : ""}",
      "${Costants.mapQuery[6]}": "${excluderepealed ? excluderepealed : ""}",
      "${Costants.mapQuery[7]}": "${type ? type : ""}",
      "${Costants.mapQuery[8]}": "${regional ? regional : ""}",
      "${Costants.mapQuery[9]}": "${related ? related : ""}",
      "${Costants.mapQuery[10]}": "${keywords ? keywords : ""}"
      }`;
  };

  var year = `{
    "${Costants.yearFromMap[0]}": "${filtersQuery.yearFrom}",
    "${Costants.yearToMap[0]}": "${filtersQuery.yearTo}"
  }`;

  var page = filtersQuery.page === -1 ? 0 : (filtersQuery.page - 1) * 10;

  let temp_query = filtersQuery.queryStandard ? filtersQuery.queryStandard : "";
  let query_builded = "";
  let query = "";
  query_builded = queryMade(JSON.parse(queryBuild()));
  if (query_builded === "" || query_builded === " " || query_builded === undefined) {
    query = temp_query;
  } else {
    query = temp_query + " " + query_builded;
  }

  // let query = temp_query + " OR" + queryMade(JSON.parse(queryBuild()));
  let facet = facetsMade(filtersQuery.facets);
  let years = yearsQueryMade(JSON.parse(year));

  let facetOptions = [
    { operatorName: "facetyears", numFacetBuckets: 100 },
    { operatorName: "countries", numFacetBuckets: 100 },
    { operatorName: "subjectselections", numFacetBuckets: 100 },
    { operatorName: "keywords", numFacetBuckets: 100 },
    { operatorName: "geographicalareas", numFacetBuckets: 100 },
    { operatorName: "typeoftexts", numFacetBuckets: 100 },
    { operatorName: "documentlanguages", numFacetBuckets: 100 },
    { operatorName: "territorialsubdivisions", numFacetBuckets: 100 },
    { operatorName: "organizationid", numFacetBuckets: 100 },
    // { operatorName: "topic", numFacetBuckets: 100 },
    { operatorName: "mainareas", numFacetBuckets: 100 },
  ];

  let queryAdvSearch = "";

  if (filtersQuery.sort !== "relevance") {
    queryAdvSearch = {
      query: `* ${years} ${query} ${facet}`,
      requestOptions: {
        searchApplicationId:
          "searchapplications/1be285f8874b8c6bfaabf84aa9d0c1be",
          
      },
      facetOptions: facetOptions,
      sortOptions: {
        operatorName: "byyear",
        sortOrder: "DESCENDING",
      },
      start: page,
    };
  } else {
    queryAdvSearch = {
      query: `* ${years} ${query} ${facet}`,
      requestOptions: {
        searchApplicationId:
          "searchapplications/1be285f8874b8c6bfaabf84aa9d0c1be",
      },
      facetOptions: facetOptions,
      start: page,
    };
  }

  return JSON.stringify(queryAdvSearch);
};
