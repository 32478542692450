/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { useLocation } from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

const HeadLanguage = ({ lang }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selected, setIsSelected] = useState("en")

  const splitUrl = (lang) => {
    let newURL;
    if (location.pathname === "/" || location.pathname.includes(".html")) {
      newURL = location.pathname.split("/").slice(0, -1).join("/") + "/" + lang;
    } else {
      lang = lang.replace(".html", "");
      newURL = location.pathname.split("/").slice(0, -1).join("/") + "/" + lang;
    }
    return newURL + location.search;
  };

  function isSelected(languageSelected) {
    if (languageSelected === lang) {
      return "selected";
    } else return "";
  }

  return (
    <div className="align-self-center" style={{}}>
        <div className="d-flex">
          <select
            name="selectLang"
            // onChange={(event) => {
            //   history.push(splitUrl(event.target.value));
            //   window.location.reload();
            // }}
            className="select-lang"
            id="selectLang"
            defaultValue={selected}
            disabled
          >
            <option value="en">
              ENGLISH
            </option>
            <option value="ar">
              العربية
            </option>
            <option value="zh">
              ﻿中文
            </option>
            <option value="ru">
              Русский
            </option>
            <option value="fr">
              FRANÇAIS
            </option>
            <option value="es">
              ESPAÑOL
            </option>
          </select>
         
        </div>
    </div>
  );
};

export default HeadLanguage;
