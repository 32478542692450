import { FormattedMessage } from "react-intl";
import "./AdvancedSearch.css";
import React, {
  useEffect,
  useState,
} from "react";
import Config from "../../config";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { useIntl } from "react-intl";
import { Costants } from "../../helper/Costants";
import { QueryAdvSearch } from "../../helper/QueryAdvSearch";
import { Chip } from "primereact/chip";
import Pagination from "../../helper/Paginator/Pagination";
import TypeSel from "../../helper/TypeSel";
import CountrySel from "../../helper/CountrySel";
import TitleDateSel from "../../helper/TitleDateSel";
import Facets from "../../helper/Facets";
import { Link, useParams } from "react-router-dom";
import ViewSpinner from "../../shared/Spinner";
import InfoSel from "../../helper/InfoSel";

function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

function AdvancedSearch() {
  const intl = useIntl();
  const { typeSel } = useParams();
  const [sortLoad, setSortLoad] = useState([]);
  const [countryLoad, setCountryLoad] = useState([]);
  const [relatedLoad, setRelatedLoad] = useState([]);
  const [regionalLoad, setRegionalLoad] = useState([]);
  const [keywordsLoad, setKeywordsLoad] = useState([]);
  const [geographicLoad, setGeographicLoad] = useState([]);
  const [queryStandard, setQueryStandard] = useState("");
  const [queryString, setQueryString] = useState("");
  const [typeLoading, setTypeLoading] = useState([{ name: "" }]);
  const [primarySubjectsLoad, setPrimarySubjectsLoad] = useState([
    { name: "" },
  ]);
  const [sort, setSort] = useState(
    intl.formatMessage({ id: "Advanced_search.sorting.relevance" })
  );
  const [country, setCountry] = useState([]);
  const [related, setRelated] = useState([]);
  const [regional, setRegional] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [type, setType] = useState([]);
  const [primarySubjects, setPrimarySubjects] = useState([]);
  const [operatorTitle, setOperatorTitle] = useState("AND");
  const [operatorCountry, setOperatorCountry] = useState("OR");
  const [operatorRelated, setOperatorRelated] = useState("OR");
  const [operatorRegional, setOperatorRegional] = useState("OR");
  const [operatorKeywords, setOperatorKeywords] = useState("OR");
  const [operatorTerritorial, setOperatorTerritorial] = useState("OR");
  const [geographic, setGeographic] = useState([]);
  const [yearFrom, setYearFrom] = useState("");
  const [yearTo, setYearTo] = useState("");
  const [title, setTitle] = useState("");
  const [record, setRecord] = useState("");
  const [repealed, setRepealed] = useState(true);
  const [facets, setFacets] = useState([]);
  const [count, setCount] = useState(0);
  const [selectFaces, setSelectFaces] = useState([]);
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [click, setClick] = useState(false);
  const [typeSend, setTypeSend] = useState([]);
  const [view, setView] = useState(false);

  let url = window.location.href.toString().split("?")[0];
  let lang = url.substring(url.length - 2);
  if (
    lang !== "fr" &&
    lang !== "es" &&
    lang !== "ru" &&
    lang !== "ar" &&
    lang !== "zh"
  ) {
    lang = "en";
  }
  document.documentElement.setAttribute("lang", lang);

  useEffect(() => {
    fetch(Config.apis.countryprofiles)
      .then((data) => data.json())
      .then((data) => {
        setCountryLoad(data.profiles);
      });

    fetch(Config.apis.geo)
      .then((data) => data.json())
      .then((data) => setGeographicLoad(data));

    fetch(Config.apis.related)
      .then((data) => data.json())
      .then((data) => setRelatedLoad(data));

    fetch(Config.apis.regional)
      .then((data) => data.json())
      .then((data) => setRegionalLoad(data));

    fetch(Config.apis.keywords)
      .then((data) => data.json())
      .then((data) => setKeywordsLoad(data));

    fetch(Config.apis.defaultQuery)
      .then((data) => data.json())
      .then((data) => {
        if (data) {
          var temp_query = data.subsets.filter(
            (el) => el.name === "CITES-LEX"
          )[0].query;
        }
        setQueryStandard(temp_query);
      });

    var primarySubjectsss = [];
    for (let el of Costants.psJson) {
      var mapPrimarySubjects = {
        id: el.id,
        name: intl.formatMessage({
          id: `Advanced_search.primary_subjects.${el.name}`,
        }),
      };
      primarySubjectsss.push(mapPrimarySubjects);
    }

    setPrimarySubjectsLoad(primarySubjectsss.sort(compare));

    var typesss = [];
    for (let el of Costants.typeJson) {
      var mapTypes = {
        id: el.id,
        name: intl.formatMessage({ id: `Advanced_search.types.${el.name}` }),
      };
      typesss.push(mapTypes);
    }
    setTypeLoading(typesss);

    var sorting = [];
    for (let el of Costants.optionSort) {
      sorting.push(intl.formatMessage({ id: `Advanced_search.sorting.${el}` }));
    }
    setSortLoad(sorting);
  }, [intl]);

  useEffect(() => {
    if (click) {
      setIsLoading(false);
      setView(true);
      setTypeSend(type);

      var data = {
        record: record,
        title: title,
        operatorTitle: operatorTitle,
        type: type,
        primarySubjects: primarySubjects,
        country: country,
        related: related,
        operatorRelated: operatorRelated,
        regional: regional,
        keywords: keywords,
        operatorKeywords: operatorKeywords,
        operatorCountry: operatorCountry,
        geographic: geographic,
        operatorTerritorial: operatorTerritorial,
        operatorRegional: operatorRegional,
        yearFrom: yearFrom,
        yearTo: yearTo,
        repealed: repealed,
        page: currentPage,
        facets: selectFaces,
        sort: sort,
        lang: lang,
        queryStandard: queryStandard,
      };

      setQueryString(QueryAdvSearch(data));

      fetch(Config.apis.gcs, {
        method: "POST",
        body: QueryAdvSearch(data),
      })
        .then((response) => response.json())
        .then((data) => {
          setCount(data.resultCountExact || data.resultCountExtimate);
          setFacets(data.facetResults);
          setResults(data);
        })
        .then(() => setTimeout(() => setIsLoading(true), 1000));

      if (currentPage === -1) setCurrentPage(1);
      setClick(false);
    }
  }, [
    currentPage,
    selectFaces,
    sort,
    click,
    type,
    record,
    title,
    operatorTitle,
    primarySubjects,
    country,
    related,
    operatorRelated,
    regional,
    keywords,
    operatorKeywords,
    operatorCountry,
    geographic,
    operatorTerritorial,
    operatorRegional,
    yearFrom,
    yearTo,
    repealed,
    lang,
    queryStandard,
    queryString,
  ]);

  useEffect(() => {
    var elmntToView = document.getElementById("header-results");
    if (results.length !== 0 && isLoading) elmntToView.scrollIntoView();
  }, [isLoading, results.length]);

  useEffect(() => {
    var typeSelArray = [];
    if (typeSel) {
      setClick(true);
      typeSelArray.push(typeSel.replace("%20", " "));
      setType(typeSelArray);
    }
  }, [typeSel]);

  const checkboxChange = () => {
    if (repealed) {
      setRepealed(false);
    } else {
      setRepealed(true);
    }
  };

  const reset = () => {
    setType("");
    setPrimarySubjects([]);
    setCountry([]);
    setRelated([]);
    setRegional([]);
    setKeywords([]);
    setOperatorTitle("AND");
    setGeographic([]);
    setOperatorCountry("OR");
    setOperatorRegional("OR");
    setOperatorRelated("OR");
    setOperatorKeywords("OR");
    setYearFrom("");
    setYearTo("");
    setOperatorTerritorial("OR");
    setTitle("");
    setRecord("");
    setSort("relevance");
    setRepealed(false);
    setCurrentPage(-1);
    setIsLoading(true);
    setClick(false);
    setView(false);
    setResults([]);
    setSelectFaces([]);
    setFacets([]);

    window.scrollTo(0, 0);
  };

  const downloadSummary = () => {
    let param_query = btoa(queryString);
    let param_language = lang;
    if (
      param_query === "" ||
      param_query === " " ||
      param_query === undefined
    ) {
      return;
    } else {
      let link =
        "https://faolex-query-download-dot-fao-faolex-dev.ey.r.appspot.com/?lang=" +
        param_language +
        "&query=" +
        param_query;
      return window.open(link, "_blank");
    }
  };

  const pageChange = (page) => {
    setCurrentPage(page);
    setClick(true);
  };

  const sortChange = (sort) => {
    setSort(sort);
    if (results.length !== 0) setClick(true);
  };

  const facetChange = (e, typeLoad, action) => {
    let _change = [...selectFaces];
    if (action === "add") {
      _change.push({ name: e, typeLoad: typeLoad });
    } else {
      _change.splice(_change.indexOf(e.value), 1);
    }
    setSelectFaces(_change);
    setClick(true);
  };

  const onCheckboxChange = (e, array) => {
    let _change = [...array];

    if (e.checked) _change.push(e.value);
    else _change.splice(_change.indexOf(e.value), 1);

    setPrimarySubjects(_change);
  };

  const onCheckboxTypeChange = (e, array) => {
    let _change = [...array];

    if (e.checked) _change.push(e.value);
    else _change.splice(_change.indexOf(e.value), 1);

    setType(_change);
  };

  useEffect(() => {
    window.addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("search").click();
      }
    });
  }, []);

  const changeCountry = (e) => {
    let country = e.value.country_ISO3_Code;
    window.location.href = `/country/profiles/${country}`;
  };

  return (
    <div>
      <header>
        <div className="header-country-profile">
          <div className="menu-container d-flex justify-content-start align-items-center">
            <div className="text-header" style={{ marginLeft: 60 }}>
              <a className="link-header" href="/">
                <i
                  class="bi bi-house-door-fill"
                  style={{ height: 31, fontWeight: "bold" }}
                ></i>
              </a>
            </div>
            <div className="text-header ms-4">
              <a className="link-header" href="/country">
                Countries
              </a>
            </div>
            <div className="ms-4">
              <Dropdown
                id="country"
                className="country-multi-header"
                value={country}
                onChange={(e) => changeCountry(e)}
                options={countryLoad}
                optionLabel={`country_${lang}`}
                placeholder="Select a country..."
                filter
              />
            </div>
            <div className="ms-4">
              <Link to="/search" className="text-button">
                <button className="button-main">
                  <i
                    id="asideToggleIcon"
                    className="bi bi-search inside-icon"
                  ></i>
                  <span className="ms-3">
                    <FormattedMessage id="Navigation.advanced" />
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </header>
      <h2 className="page-title title">
        <FormattedMessage id="Advanced_search.title" />
      </h2>
      <div id="adv-fields">
        <div className="row m-3 w-50">
          <h5>
            <FormattedMessage id="Advanced_search.words_record" />
          </h5>
          <div className="col">
            <InputText
              id="full"
              value={record}
              className="inputs-text"
              onChange={(e) => setRecord(e.target.value)}
            />
          </div>
        </div>
        <div className="row m-3 w-100">
          <div className="col">
            <h5>
              <FormattedMessage id="Advanced_search.words_title" />
            </h5>
            <InputText
              id="title"
              value={title}
              className="inputs-text"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="col mt-4">
            <Dropdown
              name="operatorTitle"
              className="operator-title"
              onChange={(e) => setOperatorTitle(e.value)}
              id="operatorTitle"
              options={["AND", "OR"]}
              value={operatorTitle}
            />
          </div>
        </div>
        <div className="row m-3">
          <div className="primary-subject-wrap">
            <div className="checkbox">
              {primarySubjectsLoad.slice(0, 3).map((p, i) => (
                <div className="row" key={i}>
                  <div className="col">
                    <Checkbox
                      onChange={(e) => onCheckboxChange(e, primarySubjects)}
                      value={p.id}
                      checked={primarySubjects.includes(p.id)}
                    ></Checkbox>
                    <label className="ml-2">{p.name}</label>
                  </div>
                </div>
              ))}
            </div>
            <div className="checkbox">
              {primarySubjectsLoad.slice(3, 6).map((p, i) => (
                <div className="row" key={i}>
                  <div className="col">
                    <Checkbox
                      onChange={(e) => onCheckboxChange(e, primarySubjects)}
                      value={p.id}
                      checked={primarySubjects.includes(p.id)}
                    ></Checkbox>
                    <label className="ml-2">{p.name}</label>
                  </div>
                </div>
              ))}
            </div>
            <div className="checkbox">
              {primarySubjectsLoad.slice(6, 9).map((p, i) => (
                <div className="row" key={i}>
                  <div className="col">
                    <Checkbox
                      onChange={(e) => onCheckboxChange(e, primarySubjects)}
                      value={p.id}
                      checked={primarySubjects.includes(p.id)}
                    ></Checkbox>
                    <label className="ml-2">{p.name}</label>
                  </div>
                </div>
              ))}
            </div>
            <div className="checkbox">
              {primarySubjectsLoad.slice(9, 12).map((p, i) => (
                <div className="row" key={i}>
                  <div className="col">
                    <Checkbox
                      onChange={(e) => onCheckboxChange(e, primarySubjects)}
                      value={p.id}
                      checked={primarySubjects.includes(p.id)}
                    ></Checkbox>
                    <label className="ml-2">{p.name}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col">
            <h5>
              <FormattedMessage id="Advanced_search.territorial" />
            </h5>
            <MultiSelect
              id="country"
              className="territorial-multi"
              value={geographic}
              onChange={(e) => setGeographic(e.value)}
              options={geographicLoad}
              optionLabel={lang}
              display="chip"
              filter
            />
          </div>
          <div className="col">
            <Dropdown
              name="operatorTerritorial"
              className="operator"
              onChange={(e) => setOperatorTerritorial(e.value)}
              id="operatorTerritorial"
              options={["AND", "OR"]}
              value={operatorTerritorial}
            />
          </div>
        </div>

        <div className="row m-3">
          <div className="col">
            <h5>
              <FormattedMessage id="Advanced_search.country" />
            </h5>
            <MultiSelect
              id="country"
              className="country-multi"
              value={country}
              onChange={(e) => setCountry(e.value)}
              options={countryLoad}
              optionLabel={`country_${lang}`}
              display="chip"
              filter
            />
          </div>
          <div className="col">
            <Dropdown
              name="operatorCountry"
              className="operator"
              onChange={(e) => setOperatorCountry(e.value)}
              id="operatorCountry"
              options={["AND", "OR"]}
              value={operatorCountry}
            />
          </div>
        </div>

        <div className="row m-3">
          <div className="col">
            <h5>
              <FormattedMessage id="Advanced_search.regional" />
            </h5>
            <MultiSelect
              id="regional"
              className="country-multi"
              value={regional}
              onChange={(e) => setRegional(e.value)}
              options={regionalLoad}
              optionLabel={`country_${lang}`}
              display="chip"
              filter
            />
          </div>
          <div className="col">
            <Dropdown
              name="operatorRegional"
              className="operator"
              onChange={(e) => setOperatorRegional(e.value)}
              id="operatorRegional"
              options={["AND", "OR"]}
              value={operatorRegional}
            />
          </div>
        </div>

        <div className="row m-3">
          <div className="col">
            <h5>
              <FormattedMessage id="Advanced_search.instruments" />
            </h5>
            <MultiSelect
              id="related"
              className="country-multi"
              value={related}
              onChange={(e) => setRelated(e.value)}
              options={relatedLoad}
              optionLabel={`${lang}`}
              display="chip"
              filter
            />
          </div>
          <div className="col">
            <Dropdown
              name="operatorRelated"
              className="operator"
              onChange={(e) => setOperatorRelated(e.value)}
              id="operatorRelated"
              options={["AND", "OR"]}
              value={operatorRelated}
            />
          </div>
        </div>

        <div className="row m-3">
          <div className="col">
            <h5>
              <FormattedMessage id="Advanced_search.year" />
            </h5>
            <label>
              <FormattedMessage id="Advanced_search.from" />
            </label>
            <InputText
              id="yearFrom"
              value={yearFrom}
              onChange={(e) => setYearFrom(e.target.value)}
            />
            <label>
              <FormattedMessage id="Advanced_search.to" />
            </label>
            <InputText
              id="yearTo"
              value={yearTo}
              onChange={(e) => setYearTo(e.target.value)}
            />
          </div>
        </div>

        <div className="row m-3">
          <div className="col">
            <h5>
              <FormattedMessage id="Advanced_search.keywords" />
            </h5>
            <MultiSelect
              id="keywords"
              className="country-multi"
              value={keywords}
              onChange={(e) => setKeywords(e.value)}
              options={keywordsLoad}
              optionLabel={`${lang}`}
              display="chip"
              filter
            />
          </div>
          <div className="col">
            <Dropdown
              name="operatorKeywords"
              className="operator"
              onChange={(e) => setOperatorKeywords(e.value)}
              id="operatorKeywords"
              options={["AND", "OR"]}
              value={operatorKeywords}
            />
          </div>
        </div>

        <div className="row m-3">
          <div className="topics-wrap">
            <h5>
              <FormattedMessage id="Advanced_search.type" />
            </h5>
            <div className="primary-subject-wrap">
              <div className="checkbox">
                {typeLoading.slice(0, 2).map((t, i) => (
                  <div className="row" key={i}>
                    <div className="col">
                      <Checkbox
                        onChange={(e) => onCheckboxTypeChange(e, type)}
                        value={t.id}
                        checked={type.includes(t.id)}
                      ></Checkbox>
                      <label className="ml-2">{t.name}</label>
                    </div>
                  </div>
                ))}
              </div>
              <div className="checkbox">
                {typeLoading.slice(2, 4).map((t, i) => (
                  <div className="row" key={i}>
                    <div className="col">
                      <Checkbox
                        onChange={(e) => onCheckboxTypeChange(e, type)}
                        value={t.id}
                        checked={type.includes(t.id)}
                      ></Checkbox>
                      <label className="ml-2">{t.name}</label>
                    </div>
                  </div>
                ))}
              </div>
              <div className="checkbox">
                {typeLoading.slice(4, 6).map((t, i) => (
                  <div className="row" key={i}>
                    <div className="col">
                      <Checkbox
                        onChange={(e) => onCheckboxTypeChange(e, type)}
                        value={t.id}
                        checked={type.includes(t.id)}
                      ></Checkbox>
                      <label className="ml-2">{t.name}</label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row m-3">
          <div className="col">
            <h5>
              <FormattedMessage id="Advanced_search.sort" />
            </h5>
            <Dropdown
              className="sort-drop"
              name="sort"
              onChange={(e) => sortChange(e.value)}
              id="sort"
              options={sortLoad}
              value={sort}
            />
          </div>
        </div>
        <div className="row m-3">
          <div className="repealed-wrap">
            <div className="repealed-text">
              <h5>
                <FormattedMessage id="Advanced_search.repealed" />
              </h5>
            </div>
            <div className="repealed-check">
              <Checkbox
                inputId="domain"
                name="domain"
                value={repealed}
                onChange={checkboxChange}
                checked={repealed}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="row">
            <div className="col">
              <button
                id="search"
                typeLoad="submit"
                className="btn btn-primary me-4"
                name="search"
                onClick={() => setClick(true)}
              >
                <FormattedMessage id="Advanced_search.button_search" />
              </button>
              <button
                id="download"
                typeLoad="submit"
                className="btn btn-primary me-4"
                name="download"
                onClick={downloadSummary}
              >
                <FormattedMessage id="Advanced_search.button_download" />
              </button>
              <button
                typeLoad="button"
                className="btn btn-primary me-4"
                name="reset"
                onClick={reset}
              >
                <FormattedMessage id="Advanced_search.button_reset" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div id="header-results">
          <div className="row m-3">
            {currentPage !== -1 && results.results ? (
              <div className="col-4">{`Page ${currentPage} of ${
                count
                  ? count % 10 === 0
                    ? ~~(count / 10)
                    : ~~(count / 10) + 1
                  : 0
              }`}</div>
            ) : null}
            {results.results ? (
              <div className="col-3">
                <p>{count} Record found</p>
              </div>
            ) : view ? (
              <div className="row mt-3">
                <div className="col-5"></div>
                <div className="col-5 m-5">
                  <h3>No record found</h3>
                </div>
              </div>
            ) : null}
            <div className="col-2">
              {results.results && results.results.length !== 0 ? (
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage ? currentPage : 1}
                  totalCount={count}
                  pageSize={10}
                  onPageChange={(page) => pageChange(page)}
                />
              ) : null}
            </div>
          </div>
          {selectFaces.length !== 0
            ? selectFaces.map((facet) => (
                <Chip
                  key={facet.name.split("#")[0]}
                  label={facet.name.split("#")[0]}
                  removable
                  onRemove={() =>
                    facetChange(facet, facet.operatorName, "remove")
                  }
                />
              ))
            : null}
          <div id="results-adv" className="row">
            <div className="col">
              {results.results && results.results.length !== 0
                ? results.results.map((res) => (
                    <div className="result-space" key={res.title}>
                      <div className="d-flex justify-content-start align-items-start">
                        <CountrySel fields={res.metadata.fields} />
                        {res.metadata.fields.map((field) => {
                          if (field.name === "repealed") {
                            if (
                              field.textValues &&
                              field.textValues.values[0] === "REPEALED"
                            ) {
                              return <span className="repealed">REPEALED</span>;
                            }
                          }
                          return null;
                        })}
                      </div>

                      <div>
                        <TitleDateSel
                          fields={res.metadata.fields}
                          pdf={res.title}
                          language={lang}
                        />
                      </div>

                      <div>
                        <InfoSel fields={res.metadata.fields} language={lang} />
                      </div>
                      <TypeSel
                        fields={res.metadata.fields}
                        typeSelected={typeSend}
                      />
                    </div>
                  ))
                : null}
              {results.results && results.results.length !== 0 ? (
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage ? currentPage : 1}
                  totalCount={count ? count : 0}
                  pageSize={10}
                  onPageChange={(page) => pageChange(page)}
                />
              ) : null}
            </div>
            <div className="col-2">
              <Facets facet={facets} facetChange={facetChange} />
            </div>
          </div>
        </div>
      ) : (
        <div className="loading">
          <div className="wrap-spinner">
            {" "}
            <ViewSpinner />{" "}
          </div>
        </div>
      )}
    </div>
  );
}

export default AdvancedSearch;
