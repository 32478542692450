import { useEffect, useState } from "react";
import { Tooltip } from "primereact/tooltip";

function InfoSel({ fields, language }) {
  const [faoId, setFaoId] = useState("");
  const [year, setYear] = useState("");
  const [typeOfText, setTypeOfText] = useState("");
  const [relatedResources, setRelatedResources] = useState([]);

  useEffect(() => {
    fields.forEach((field) => {
      if (field.name.includes("faolexId")) {
        setFaoId(field.textValues.values[0]);
      }

      if (field.name.includes("year")) {
        setYear(field.integerValues.values[0]);
      }

      if (field.name.includes("typeOfTextEn")) {
        setTypeOfText(field.textValues.values[0].split("#")[0]);
      }

      if (field.name === "alternativeLinks") {
        let arrayOfRelatedResources = [];
        field.textValues.values.forEach((link) => {
          arrayOfRelatedResources.push(link);
        });
        setRelatedResources(arrayOfRelatedResources);
      }
    });
  }, [fields]);

  return (
    <>
      <div className="row">
        <div className="col-6 mt-3">
          <div>
            <span className="infoDocs">FAOLEX No:</span>
            <span className="ms-2">
              {faoId}
            </span>
          </div>
        </div>
        {relatedResources.length > 0 ? (
          <div className="col-6 mt-3">
            <div>
              <span className="infoDocs">Related Resources:</span>
              <span className="ms-2">
                {relatedResources.map((link) => (
                  <span key={link}>
                    <Tooltip target=".tooltip-button" />
                    <a href={link} target="_blank" rel="noreferrer">
                    <i className="bi bi-file-earmark-pdf tooltip-button" data-pr-tooltip={link}></i>
                    </a>
                  </span>
                ))}
              </span>
            </div>
          </div>
        ) : null}
        {/* <div className="col-6">
          <div>
            <span className="infoDocs">By Year:</span>
            <span className="ms-2">
              {year}
            </span>
          </div>
        </div> */}
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <div>
            <span className="infoDocs">Type Of Text:</span>
            <span className="ms-2">
              {typeOfText === 'Legislation' ? 'Law' : typeOfText} 
            </span>
          </div>
        </div>
        
        
      </div>
    </>
  );
}

export default InfoSel;
