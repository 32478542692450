import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import Config from "../../config";
import { Link } from "react-router-dom";
import "./Home.css";
import ViewSpinner from "../../shared/Spinner";
import publ01 from "../../images/_home/Publ01.png";
import publ02 from "../../images/_home/Publ02.png";

function Home() {
  const [relevant, setRelevant] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  var dateLang = window.location.pathname.substring(
    window.location.pathname.length - 2
  );
  if (
    dateLang !== "fr" &&
    dateLang !== "es" &&
    dateLang !== "ru" &&
    dateLang !== "ar" &&
    dateLang !== "zh"
  ) {
    dateLang = "en";
  }
  var lang = dateLang ? dateLang : "en";

  useEffect(() => {
    fetch(Config.apis.relevant)
      .then((data) => data.json())
      .then((data) => {
        setIsLoading(false);
        setRelevant(data.related_websites);
      })
      .then(() => setTimeout(setIsLoading(true), 1000));
  }, [relevant]);

  return (
    <div>
      {isLoading ? (
        <div className="main">
          <div className="home">
            <div className="color-background">
              <div className="card-title">
                <p style={{ color: "white" }}>
                  <FormattedMessage id="Home.intro" />
                </p>
              </div>
              <div className="button-wrap">
                <Link to="search" className="text-button">
                  <button className="button-main">
                    <i
                      id="asideToggleIcon"
                      className="bi bi-search inside-icon"
                    ></i>
                    <span className="ms-3">
                      <FormattedMessage id="Navigation.advanced" />
                    </span>
                  </button>
                </Link>

                <Link to="country" className="text-button">
                  <button className="button-main">
                    <i
                      id="asideToggleIcon"
                      className="bi bi-globe inside-icon"
                    ></i>
                    <span className="ms-3">
                      <FormattedMessage id="Navigation.country" />
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <section className="home-content">
            <div className="row mt-5">
              <div className="col-9">
                <div className="blue-box">
                  <div className="title-box">
                    <FormattedMessage id="Home.subtitle" />
                  </div>
                  <div className="text-box mt-2">
                    <FormattedMessage
                      id="Home.text_box1"
                      values={{
                        link: (chunks) => (
                          <a
                            href="https://cites.org/eng/disc/text.php"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="text-color">{chunks}</span>
                          </a>
                        ),
                      }}
                    />
                  </div>
                  <div className="text-box mt-3">
                    <FormattedMessage
                      id="Home.text_box2"
                      values={{
                        link: (chunks) => (
                          <a
                            href="https://cites.org/eng/app/index.php"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="text-color">{chunks}</span>
                          </a>
                        ),
                      }}
                    />
                  </div>
                  <div className="text-box mt-3">
                    <FormattedMessage
                      id="Home.text_box3"
                      values={{
                        link: (chunks) => (
                          <a
                            href="https://cites.org/sites/default/files/projects/NLP/E-Model_law-revised_Oct.2021.FINAL.DRAFT.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="text-color">{chunks}</span>
                          </a>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="d-flex justify-content-start align-items-start">
                    <div>
                      <div className="image-home">
                        <img
                          src="https://faolex.fao.org/docs/json/citeslex2.jpg"
                          alt="test"
                        ></img>
                      </div>
                    </div>
                    <div className="ms-5">
                      <div className="text-box">
                        <FormattedMessage
                          id="Home.text_box4"
                          values={{
                            bold: (chunks) => (
                              <span className="text-bold">{chunks}</span>
                            ),
                            p: (chunks) => <p>{chunks}</p>,
                            link1: (chunks) => (
                              <a
                                href="https://cites.org/eng/meetings/cop"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span className="text-color">{chunks}</span>
                              </a>
                            ),
                            link2: (chunks) => (
                              <a
                                href="https://cites.org/sites/default/files/documents/COP/19/resolution/E-Res-08-04-R15.pdf"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span className="text-color">{chunks}</span>
                              </a>
                            ),
                            link3: (chunks) => (
                              <a
                                href="https://cites.org/sites/default/files/documents/COP/19/resolution/E-Res-18-07-R19.pdf"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span className="text-color">{chunks}</span>
                              </a>
                            ),
                            link4: (chunks) => (
                              <a
                                href="https://cites.org/eng/legislation"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span className="text-color">{chunks}</span>
                              </a>
                            ),
                            link5: (chunks) => (
                              <a
                                href="https://www.fao.org/faolex/en/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span className="text-color">{chunks}</span>
                              </a>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <div className="blue-light-box">
                      <div className="row">
                        <div className="col-3">
                          <img src={publ01} alt="publ01"></img>
                        </div>
                        <div className="col-9">
                          <div className="title-card-box">
                            <FormattedMessage id="Home.title_box_card1" />
                          </div>
                          <div className="text-card-box mt-3">
                            <FormattedMessage
                              id="Home.text_box_card1"
                              values={{
                                p: (chunks) => <p>{chunks}</p>,
                                en: (chunks) => (
                                  <a
                                    href="https://www.fao.org/documents/card/en/c/cc8051en"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <span className="text-color">{chunks}</span>
                                  </a>
                                ),
                                es: (chunks) => (
                                  <a
                                    href="https://www.fao.org/documents/card/en/c/CC8051ES"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <span className="text-color">{chunks}</span>
                                  </a>
                                ),
                                fr: (chunks) => (
                                  <a
                                    href="https://www.fao.org/documents/card/en/c/CC8051FR"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <span className="text-color">{chunks}</span>
                                  </a>
                                ),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="blue-light-box">
                      <div className="row">
                        <div className="col-3">
                          <img src={publ02} alt="publ02"></img>
                        </div>
                        <div className="col-9">
                          <div className="title-card-box">
                            <FormattedMessage id="Home.title_box_card2" />
                          </div>
                          <div className="text-card-box mt-3">
                            <FormattedMessage
                              id="Home.text_box_card2"
                              values={{
                                list: (chunks) => <ul>{chunks}</ul>,
                                element: (chunks) => <li>{chunks}</li>,
                                link1: (chunks) => (
                                  <a
                                    href="https://www.fao.org/3/cc5219en/cc5219en.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <span className="text-color">{chunks}</span>
                                  </a>
                                ),
                                link2: (chunks) => (
                                  <a
                                    href="https://www.fao.org/3/cc5072en/cc5072en.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <span className="text-color">{chunks}</span>
                                  </a>
                                ),
                                link3: (chunks) => (
                                  <a
                                    href="https://www.fao.org/documents/card/es/c/cc7657es"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <span className="text-color">{chunks}</span>
                                  </a>
                                ),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <div className="text-last">
                      <FormattedMessage id="Home.text_last" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="blue-title-box">
                  <Link to="https://faolex.fao.org/docs/json/citeslex_methodology.pdf" target="_blank">
                    <i className="bi bi-file-earmark-text me-2 white-text"></i>
                    <span className="white-text">
                      <FormattedMessage id="Home.methodology" />
                    </span>
                  </Link>
                </div>
                <div className="title-box my-3">
                  <FormattedMessage id="Home.related" />
                </div>
                {relevant && relevant.length > 0
                  ? relevant.map((el) =>
                      el.category === 1 ? (
                        <div className="text-blue-box mb-2">
                          <Link to={`${el.url_en}`} target="_blank">
                            <i className="bi bi-globe me-1"></i>
                            {el.name_en}
                          </Link>
                        </div>
                      ) : null
                    )
                  : null}
                <div className="title-box mt-4 mb-3">
                  <FormattedMessage id="Home.resources" />
                </div>
                {relevant && relevant.length > 0
                  ? relevant.map((el) =>
                      el.category === 2 ? (
                        <div className="text-blue-box mb-2">
                          <Link to={`${el.url_en}`} target="_blank">
                            <i className="bi bi-globe me-1"></i>
                            {el.name_en}
                          </Link>
                        </div>
                      ) : null
                    )
                  : null}
                <div className="blue-contact-box mt-5">
                  <div className="contact-title">
                    <FormattedMessage id="Home.contact_title" />
                  </div>
                  <div className="contact-text mt-2">
                    <FormattedMessage
                      id="Home.contact_text"
                      values={{
                        color: (chunks) => (
                          <span className="text-color">{chunks}</span>
                        ),
                        link: (chunks) => (
                          <a
                            href={`mailto:${chunks}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="text-color">{chunks}</span>
                          </a>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="loading">
          <div className="wrap-spinner">
            {" "}
            <ViewSpinner />{" "}
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
