import { useEffect, useState } from "react";
import PDFicon from "../images/file-pdf-solid.svg";
import Config from "../config";

function TitleDateSel({ fields, pdf, language }) {
  const [faoId, setFaoId] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    fields.forEach((field) => {
      if (field.name.includes("faolexId")) {
        setFaoId(field.textValues.values[0]);
      }
      if (field.name === 'abstract') {
        setText(field.textValues.values[0].split("<br>")[0]);
      }
    });
  });

  return (
    <div id="title-date">
      <div className="title-date-wrap">
        <div className="pdf-icon">
          <a
            href={`${Config.urls.link_pdf}${pdf}`}
            rel="noopener noreferrer"
            target="_blank"
            className="embed"
            title="View PDF"
          >
            <img src={PDFicon} alt="" />
          </a>
        </div>
        <div className="section-title">
          {fields.map((field) => (
            <div id="results" key={field.name}>
              {field.textValues
                ? field.name === "titleOfText"
                  ? field.textValues.values.map((title) =>
                    title ? (
                      <h5 className="title-link" key={title} style={{marginRight: 100}}>
                        <a href={`https://www.fao.org/faolex/results/details/${language}/c/${faoId}`} target="_blank" rel="noreferrer">
                          {title}
                        </a>
                      </h5>
                    ) : null
                  )
                  : null
                : null}
            </div>
          ))}
        </div>
        <div className="section-date">
          <div id="results">
            <span className="ms-1">
            {fields.map((field) => (
              field.textValues
                  ? field.name === "dateOfOriginalText"
                    ? field.textValues.values.map((date) => (
                        <span key={date}>
                          <small key={date}>| Date of text: {date}</small>
                        </span>
                      ))
                    : null
                  : null
            ))
              }
            </span>
            <span className="ms-1">
            {fields.map((field) => (
              field.textValues
                  ? field.name === "dateOfText"
                    ? field.textValues.values.map((date) => (
                        <span key={date}>
                          <small key={date}>| Date of text: {date}</small>
                        </span>
                      ))
                    : null
                  : null
            ))
              }
            </span>
            <span className="ms-1">
            {fields.map((field) => (
              field.textValues
                  ? field.name === "dateOfConsolidation"
                    ? field.textValues.values.map((date) => (
                        <span key={date}>
                          <small key={date}>| As last amended: {date}</small>
                        </span>
                      ))
                    : null
                  : null
            ))
              }
            </span>
          </div>
        </div>
      </div>
      <div className="abstract">
        <div id="results">
          <p>
            <small>{text}</small>
          </p>
        </div>
      </div>
    </div>
  );
}

export default TitleDateSel;
