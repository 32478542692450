export class Costants {
  static optionSort = ["relevance","date_of_text"];

  // static topicJson = [
  //   { id: "WE", name: "women" },
  //   { id: "TS", name: "measures" },
  //   { id: "GNA", name: "natural_resurces" },
  //   { id: "GNU", name: "nutrition" },
  //   { id: "GS", name: "social_protection" },
  //   { id: "GF", name: "financial_services" }
  // ];

  static typeJson = [
    { id: "P", name: "policies" },
    { id: "L", name: "laws" },
    { id: "R", name: "regulations" },
    { id: "M", name: "miscellaneous" },
    { id: "C", name: "constitutions" },
    { id: "A", name: "agreements" }
  ];

  static psJson = [
    { id: "AG", name: "agricolture" },
    { id: "PL", name: "cultivated_plants" },
    { id: "EN", name: "enviroment" },
    { id: "FI", name: "fisheries" },
    { id: "FD", name: "food_nutrition" },
    { id: "FO", name: "forestry" },
    { id: "LA", name: "land_soil" },
    { id: "AN", name: "livestock" },
    { id: "MR", name: "mineral_resources" },
    { id: "SE", name: "sea" },
    { id: "WA", name: "water" },
    { id: "WB", name: "spicies_eco" }


    // { id: "Agricultural and rural development", name: "agricolture" },
    // { id: "Cultivated plants", name: "cultivated_plants" },
    // { id: "Environment", name: "enviroment" },
    // { id: "Fisheries and Aquaculture", name: "fisheries" },
    // { id: "Livestock", name: "livestock" },
    // { id: "Food and nutrition", name: "food_nutrition" },
    // { id: "Forestry", name: "forestry" },
    // { id: "Land and soil", name: "land_soil" },
    // { id: "Mineral resources and Energy", name: "mineral_resources" },
    // { id: "Sea", name: "sea" },
    // { id: "Water", name: "water" },
    // { id: "Wild species and ecosystems", name: "spicies_eco" },
    
  ];

  static mapQuery = [
    "record",
    "title",
    "topic",
    "subjectselection",
    "country",
    "geographicalareas",
    "NOT repealed",
    "typeoftext",
    "organizationid",
    "implementstre",
    "keyword"
  ];

  static facetsMap = [
    {field: "countries", name: "By Country/Territory", priority: 7},
    {field: "mainareas", name: "By Primary Subject", priority: 3},
    {field: "subjectselections", name: "By Domain", priority: 2},
    {field: "keywords", name: "By Keywords", priority: 4},
    {field: "geographicalareas", name: "By Geographical Area", priority: 5},
    {field: "typeoftexts", name: "By Type Of Text", priority: 9},
    {field: "documentlanguages", name: "By Languages", priority: 11},
    {field: "territorialsubdivisions", name: "By Territorial Subdivision", priority: 8},
    {field: "organization", name: "By Regional Organization", priority: 6},
    {field: "facetyear", name: "By Year", priority: 10},
    {field: "topic", name: "By Topic", priority: 1},
    {field: "typeoftext", name: "By Type", priority: 12}
  ];

  static yearFromMap = ["yearfrom", "originalyearfrom"];
  static yearToMap = ["yearto", "originalyearto"];

  static tableInfoMap = [
    {field: "serialImprint", name: "Serial Imprint"},
    {field: "typeOfText", name: "Type of text"},
    {field: "relatedWebSite", name: "Related web site"},
    {field: "faolexid", name: "Lex."},
    {field: "documentLanguageEn", name: "Document language"},
    {field: "dateOfText", name: "Date of text"},
    {field: "dateOfOriginalText", name: "Date of original text"},
    {field: "dateOfConsolidation", name: "Last amended date"},
    {field: "keyword", name: "Keywords"},
    {field: "subjectSelectionEn", name: "Domains"},
    {field: "mainAreaEn", name: "Primary Subjects"},
    {field: "dateOfModification", name: "Date of modification"}
  ];

  static tableAllviewMap = [
    "topic",
    "type",
    "titleOfText",
    "dateOfText",
    "faolexId",
    "typeOfText"
  ]
}
