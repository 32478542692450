import { Costants } from "./Costants";

export const QueryLexDocumentsSearch = (faolexid, page) => {
 
  let facetOptions = [
    { operatorName: "year", numFacetBuckets: 100 },
    { operatorName: "countries", numFacetBuckets: 100 },
    { operatorName: "mainareas", numFacetBuckets: 100 },
    { operatorName: "subjectselections", numFacetBuckets: 100 },
    { operatorName: "keywords", numFacetBuckets: 100 },
    { operatorName: "geographicalareas", numFacetBuckets: 100 },
    { operatorName: "typeoftexts", numFacetBuckets: 100 },
    { operatorName: "documentlanguages", numFacetBuckets: 100 },
    { operatorName: "territorialsubdivisions", numFacetBuckets: 100 },
    { operatorName: "organization", numFacetBuckets: 100 },
  ];

   let queryAdvSearch = {
      query: `* faolexid:(${faolexid})`,
      requestOptions: {
        searchApplicationId:
          "searchapplications/1be285f8874b8c6bfaabf84aa9d0c1be",
      },
      facetOptions: facetOptions,
      start: page,
      pageSize: 100
    };
  
  return JSON.stringify(queryAdvSearch);
};
