import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";
// import configPws from "../config";
// import { QueryAdvSearch } from "./QueryAdvSearch";
// import { QueryCountryDocumentsSearch } from "./QueryCountryDocumentsSearch";
import CircleGreen from "../images/circle-solid-green.svg";
// import Config from "../config";
import ViewSpinner from "../shared/Spinner";
import { DownloadQueryTablePDF } from "./DownloadPDF";
import { DownloadXLS } from "./DownloadXLS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";

// let documentsss = []; // da eliminare

let document_temp = [];

function AllDocumentsView() {
  const [profile, setProfile] = useState([]);
  const [language, setLanguage] = useState("");

  const { countryId } = useParams();
  const [documents, setDocuments] = useState([]);
  const [page, setPage] = useState(0);
  const [country, setCountry] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  function Document(recId, typeOfText, title, dateOfText, jurisdiction, specificLegislation, fisheries, forestry, wildSpecies, others, biodiversity, environment, customs, indigenous, publicHealth, animalHealth, animalProduction, animalWelfare, relatedPolicies, agreements) {
    this.recId = recId
    this.typeOfText = typeOfText
    this.title = title
    this.dateOfText = dateOfText
    this.jurisdiction = jurisdiction
    this.specificLegislation = specificLegislation
    this.fisheries = fisheries
    this.forestry = forestry
    this.wildSpecies = wildSpecies
    this.others = others
    this.biodiversity = biodiversity
    this.environment = environment
    this.customs = customs
    this.indigenous = indigenous
    this.publicHealth = publicHealth
    this.animalHealth = animalHealth
    this.animalProduction = animalProduction
    this.animalWelfare = animalWelfare
    this.relatedPolicies = relatedPolicies
    this.agreements = agreements
  }

  useEffect(() => {

    let recId = "";
    let typeOfText = "";
    let title = "";
    let dateOfText = "";
    let jurisdiction = "";
    let specificLegislation = false;
    let fisheries = false;
    let forestry = false;
    let wildSpecies = false;
    let others = false;
    let biodiversity = false;
    let environment = false;
    let customs = false;
    let indigenous = false;
    let publicHealth = false;
    let animalHealth = false;
    let animalProduction = false;
    let animalWelfare = false;
    let relatedPolicies = false;
    let agreements = false;

    let object = {};

    const profile = JSON.parse(localStorage.getItem('profile'));
    setLanguage(localStorage.getItem('language'));

    if (profile) {

      profile.section.forEach((section) => {

        if (section.SectionTitle_en.includes("specific legislation")) {
          specificLegislation = true;

          for (let document of section.subsection[0].document) {
            recId = document.id;
            typeOfText = document.type_of_text.charAt(0);
            title = document.Title_of_Text;
            dateOfText = document.Date_of_Text;
            document.Territorial_Subdivision ? jurisdiction = document.Territorial_Subdivision : jurisdiction = "";

            object = new Document(recId, typeOfText, title, dateOfText, jurisdiction, specificLegislation, fisheries, forestry, wildSpecies, others, biodiversity, environment, customs, indigenous, publicHealth, animalHealth, animalProduction, animalWelfare, relatedPolicies, agreements);
            pushObject(object);
          }

        } else if (section.SectionTitle_en.includes("related legislation")) {
          specificLegislation = false;
          section.subsection?.forEach((subsection) => {
            if (subsection.SubsectionTitle_en.includes("Fisheries")) {
              fisheries = true;
              forestry = false;
              wildSpecies = false;
              others = false;
            } else if (subsection.SubsectionTitle_en.includes("Forestry")) {
              forestry = true;
              fisheries = false;
              wildSpecies = false;
              others = false;
            } else if (subsection.SubsectionTitle_en.includes("Wild species & ecosystems")) {
              wildSpecies = true;
              fisheries = false;
              forestry = false;
              others = false;
            } else if (subsection.SubsectionTitle_en.includes("Others")) {
              others = true;
              fisheries = false;
              forestry = false;
              wildSpecies = false;
            }
            subsection.document?.forEach((document) => {
              recId = document.id;
              typeOfText = document.type_of_text.charAt(0);
              title = document.Title_of_Text;
              dateOfText = document.Date_of_Text;
              document.Territorial_Subdivision ? jurisdiction = document.Territorial_Subdivision : jurisdiction = "";

              object = new Document(recId, typeOfText, title, dateOfText, jurisdiction, specificLegislation, fisheries, forestry, wildSpecies, others, biodiversity, environment, customs, indigenous, publicHealth, animalHealth, animalProduction, animalWelfare, relatedPolicies, agreements);
              pushObject(object);
            })
          })

        } else if (section.SectionTitle_en.includes("Additional legislation")) {
          specificLegislation = false;
          fisheries = false;
          forestry = false;
          wildSpecies = false;
          others = false;
          section.subsection?.forEach((subsection) => {
            if (subsection.SubsectionTitle_en.includes("Biodiversity")) {
              biodiversity = true;
              environment = false;
              customs = false;
              indigenous = false;
              publicHealth = false;
              animalHealth = false;
              animalProduction = false;
              animalWelfare = false;
            } else if (subsection.SubsectionTitle_en.includes("Environment")) {
              environment = true;
              biodiversity = false;
              customs = false;
              indigenous = false;
              publicHealth = false;
              animalHealth = false;
              animalProduction = false;
              animalWelfare = false;
            } else if (subsection.SubsectionTitle_en.includes("Customs / international trade")) {
              customs = true;
              biodiversity = false;
              environment = false;
              indigenous = false;
              publicHealth = false;
              animalHealth = false;
              animalProduction = false;
              animalWelfare = false;
            } else if (subsection.SubsectionTitle_en.includes("Indigenous peoples and local communities")) {
              indigenous = true;
              biodiversity = false;
              environment = false;
              customs = false;
              publicHealth = false;
              animalHealth = false;
              animalProduction = false;
              animalWelfare = false;
            } else if (subsection.SubsectionTitle_en.includes("Public health")) {
              publicHealth = true;
              biodiversity = false;
              environment = false;
              customs = false;
              indigenous = false;
              animalHealth = false;
              animalProduction = false;
              animalWelfare = false;
            } else if (subsection.SubsectionTitle_en.includes("Animal health")) {
              animalHealth = true;
              biodiversity = false;
              environment = false;
              customs = false;
              indigenous = false;
              publicHealth = false;
              animalProduction = false;
              animalWelfare = false;
            } else if (subsection.SubsectionTitle_en.includes("Animal production")) {
              animalProduction = true;
              biodiversity = false;
              environment = false;
              customs = false;
              indigenous = false;
              publicHealth = false;
              animalHealth = false;
              animalWelfare = false;
            } else if (subsection.SubsectionTitle_en.includes("Animal welfare")) {
              animalWelfare = true;
              biodiversity = false;
              environment = false;
              customs = false;
              indigenous = false;
              publicHealth = false;
              animalHealth = false;
              animalProduction = false;
            }
            subsection.document?.forEach((document) => {
              recId = document.id;
              typeOfText = document.type_of_text.charAt(0);
              title = document.Title_of_Text;
              dateOfText = document.Date_of_Text;
              document.Territorial_Subdivision ? jurisdiction = document.Territorial_Subdivision : jurisdiction = "";

              object = new Document(recId, typeOfText, title, dateOfText, jurisdiction, specificLegislation, fisheries, forestry, wildSpecies, others, biodiversity, environment, customs, indigenous, publicHealth, animalHealth, animalProduction, animalWelfare, relatedPolicies, agreements);
              pushObject(object);
            })
          })

        } else if (section.SectionTitle_en.includes("related policies")) {
          relatedPolicies = true;
          specificLegislation = false;
          fisheries = false;
          forestry = false;
          wildSpecies = false;
          others = false;
          biodiversity = false;
          environment = false;
          customs = false;
          indigenous = false;
          publicHealth = false;
          animalHealth = false;
          animalProduction = false;
          animalWelfare = false;

          section.subsection.forEach((subsection) => {
            subsection.document?.forEach((document) => {
              recId = document.id;
              typeOfText = document.type_of_text.charAt(0);
              title = document.Title_of_Text;
              dateOfText = document.Date_of_Text;
              document.Territorial_Subdivision ? jurisdiction = document.Territorial_Subdivision : jurisdiction = "";

              object = new Document(recId, typeOfText, title, dateOfText, jurisdiction, specificLegislation, fisheries, forestry, wildSpecies, others, biodiversity, environment, customs, indigenous, publicHealth, animalHealth, animalProduction, animalWelfare, relatedPolicies, agreements);
              pushObject(object);
            })
          })

        } else if (section.SectionTitle_en.includes("International commitments")) {
          agreements = true;
          relatedPolicies = false;
          specificLegislation = false;
          fisheries = false;
          forestry = false;
          wildSpecies = false;
          others = false;
          biodiversity = false;
          environment = false;
          customs = false;
          indigenous = false;
          publicHealth = false;
          animalHealth = false;
          animalProduction = false;
          animalWelfare = false;

          section.subsection?.forEach((subsection) => {
            if (subsection.SubsectionTitle_en.includes("Bilateral/multilateral agreements")) {
              subsection.document?.forEach((document) => {
                recId = document.id;
                typeOfText = document.type_of_text.charAt(0);
                title = document.Title_of_Text;
                dateOfText = document.Date_of_Text;
                document.Territorial_Subdivision ? jurisdiction = document.Territorial_Subdivision : jurisdiction = "";

                object = new Document(recId, typeOfText, title, dateOfText, "", specificLegislation, fisheries, forestry, wildSpecies, others, biodiversity, environment, customs, indigenous, publicHealth, animalHealth, animalProduction, animalWelfare, relatedPolicies, agreements);
                pushObject(object);
              })
            }
          })
        }
      })

      setDocuments(document_temp);
      setProfile(profile);
    }
  }, [language]);

  function pushObject(object) {
    let i = document_temp.findIndex((el) => el.recId === object.recId);

    if (i === -1) {
      document_temp.push(object);
    } else {
      if (object.typeOfText !== document_temp[i].typeOfText) {
        document_temp[i].typeOfText = document_temp[i].ṫypeOfText + ", " + object.typeOfText;
      }

      for (const key of Object.keys(object)) {
        if (object[key] === true &&
          (key.includes('specificLegislation')
            || key.includes('fisheries')
            || key.includes('forestry')
            || key.includes('wildSpecies')
            || key.includes('others')
            || key.includes('biodiversity')
            || key.includes('environment')
            || key.includes('customs')
            || key.includes('indigenous')
            || key.includes('publicHealth')
            || key.includes('animalHealth')
            || key.includes('animalProduction')
            || key.includes('animalWelfare')
            || key.includes('relatedPolicies')
            || key.includes('agreements'))
        ) {
          document_temp[i][key] = object[key];
        }
      }
    }
  }

  // useEffect(() => {
  //   setIsLoading(false);
  //   fetch(configPws.apis.gcs, {
  //     method: "POST",
  //     body: QueryCountryDocumentsSearch(countryId, page),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data.results)
  //       let id = "";
  //       let typeOfText = "";
  //       let title = "";
  //       let date = "";
  //       let topicsArray = [];
  //       let titlePdf = "";

  //       for (let el of data.results) {
  //         titlePdf = el.title;
  //         topicsArray = [];
  //         for (let dc of el.metadata.fields) {
  //           switch (dc.name) {
  //             case "faolexId":
  //               id = dc.textValues.values[0];
  //               break;
  //             case "typeOfText":
  //               typeOfText = dc.textValues.values[0];
  //               break;
  //             case "titleOfText":
  //               title = dc.textValues.values[0];
  //               break;
  //             case "dateOfText":
  //               date = dc.textValues.values[0];
  //               break;
  //             case "topic":
  //               topicsArray.push(dc.textValues.values);
  //               break;
  //             case "country":
  //               if (dc.textValues.values.length > 1) {
  //                 setCountry((c) => c);
  //               } else {
  //                 setCountry(dc.textValues.values[0].split("#")[0]);
  //               }

  //               break;

  //             default:
  //           }
  //         }

  //         let documentview = {
  //           id: id,
  //           typeOfText: typeOfText,
  //           title: title,
  //           date: date,
  //           topicresources: false,
  //           topicempowerment: false,
  //           topicnutrition: false,
  //           topicsocial: false,
  //           topicfinancial: false,
  //           topictemporary: false,
  //           pdf: titlePdf,
  //         };

  //         for (let tpl of topicsArray[0] || topicsArray) {
  //           if (tpl === "Gender and natural resources") {
  //             documentview.topicresources = true;
  //           }
  //           if (tpl === "Women's empowerment") {
  //             documentview.topicempowerment = true;
  //           }
  //           if (tpl === "Gender and nutrition") {
  //             documentview.topicnutrition = true;
  //           }
  //           if (tpl === "Gender and social protection") {
  //             documentview.topicsocial = true;
  //           }
  //           if (tpl === "Gender and financial services") {
  //             documentview.topicfinancial = true;
  //           }
  //           if (tpl === "Temporary special measures") {
  //             documentview.topictemporary = true;
  //           }
  //         }

  //         documentsss.push(documentview);
  //       }

  //       if (!data.hasMoreResults) {
  //         setDocuments(documentsss);
  //       } else {
  //         setPage(page + 100);
  //       }
  //     })
  //     .then(() => setIsLoading(true));
  // }, [countryId, page]);

  const DownloadPdf = () => {
    let rowInit = [
      [
        {
          text: "Jurisdiction",
          style: "tableHeader",
          rowSpan: 2,
          alignment: "center",
        },
        {
          text: "Rec.Id",
          style: "tableHeader",
          rowSpan: 2,
          alignment: "center",
        },
        {
          text: "Type of text",
          style: "tableHeader",
          rowSpan: 2,
          alignment: "center",
        },
        {
          text: "Title",
          style: "tableHeader",
          rowSpan: 2,
          alignment: "center",
        },
        {
          text: "Date of text",
          style: "tableHeader",
          rowSpan: 2,
          alignment: "center",
        },
        {
          text: "SPECIFIC LEGISLATION",
          style: "tableHeader",
          rowSpan: 2,
          alignment: "center",
        },
        {
          text: "RELATED LEGISLATION",
          style: "tableHeader",
          colSpan: 4,
          alignment: "center",
        },
        {},
        {},
        {},
        {
          text: "ADDITIONAL LEGISLATION",
          style: "tableHeader",
          colSpan: 8,
          alignment: "center",
        },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {
          text: "RELATED POLICIES",
          style: "tableHeader",
          rowSpan: 2,
          alignment: "center",
        },
        {
          text: "AGREEMENTS",
          style: "tableHeader",
          rowSpan: 2,
          alignment: "center",
        },
      ],
      [
        {},
        {},
        {},
        {},
        {},
        {},
        {
          text: "Fisheries",
          style: "tableHeader",
          alignment: "center",
        },
        {
          text: "Forestry",
          style: "tableHeader",
          alignment: "center",
        },
        {
          text: "Wild species & ecosystems",
          style: "tableHeader",
          alignment: "center",
        },
        {
          text: "Others",
          style: "tableHeader",
          alignment: "center",
        },
        {
          text: "Biodiversity",
          style: "tableHeader",
          alignment: "center",
        },
        {
          text: "Environment",
          style: "tableHeader",
          alignment: "center",
        },
        {
          text: "Customs / international trade",
          style: "tableHeader",
          alignment: "center",
        },
        {
          text: "Indigenous peoples and local communities",
          style: "tableHeader",
          alignment: "center",
        },
        {
          text: "Public health",
          style: "tableHeader",
          alignment: "center",
        },
        {
          text: "Animal health",
          style: "tableHeader",
          alignment: "center",
        },
        {
          text: "Animal production",
          style: "tableHeader",
          alignment: "center",
        },
        {
          text: "Animal welfare",
          style: "tableHeader",
          alignment: "center",
        },
      ]
    ];

    documents.forEach((document) => {
      rowInit.push(
        [
          {
            text: profile["country_" + language] + (document.jurisdiction !== "" ? (" (" + document.jurisdiction + ")") : ""),
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
          },
          {
            text: document.recId,
            link: "https://www.fao.org/faolex/results/details/" + language + "/c/" + document.recId,
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "blue",
          },
          {
            text: document.typeOfText,
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
          },
          {
            text: document.title,
            link: "https://www.fao.org/faolex/results/details/" + language + "/c/" + document.recId,
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "blue",
          },
          {
            text: document.dateOfText,
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
          },
          {
            text: document.specificLegislation ? "X" : "",
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "green",
            fontSize: 22,
          },
          {
            text: document.fisheries ? "X" : "",
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "green",
            fontSize: 22,
          },
          {
            text: document.forestry ? "X" : "",
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "green",
            fontSize: 22,
          },
          {
            text: document.wildSpecies ? "X" : "",
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "green",
            fontSize: 22,
          },
          {
            text: document.others ? "X" : "",
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "green",
            fontSize: 22,
          },
          {
            text: document.biodiversity ? "X" : "",
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "green",
            fontSize: 22,
          },
          {
            text: document.environment ? "X" : "",
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "green",
            fontSize: 22,
          },
          {
            text: document.customs ? "X" : "",
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "green",
            fontSize: 22,
          },
          {
            text: document.indigenous ? "X" : "",
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "green",
            fontSize: 22,
          },
          {
            text: document.publicHealth ? "X" : "",
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "green",
            fontSize: 22,
          },
          {
            text: document.animalHealth ? "X" : "",
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "green",
            fontSize: 22,
          },
          {
            text: document.animalProduction ? "X" : "",
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "green",
            fontSize: 22,
          },
          {
            text: document.animalWelfare ? "X" : "",
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "green",
            fontSize: 22,
          },
          {
            text: document.relatedPolicies ? "X" : "",
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "green",
            fontSize: 22,
          },
          {
            text: document.agreements ? "X" : "",
            // style: "tableHeader",
            rowSpan: 1,
            alignment: "center",
            color: "green",
            fontSize: 22,
          }
        ]
      );
    });

    DownloadQueryTablePDF(rowInit, profile["country_" + language]);
  };

  const downloadXls = () => {
    let filesPdf = [];
    let formatXlsJson = [
      {
        recId: "Rec.Id",
        typeOfText: "Type of text",
        title: "Title",
        dateOfText: "Date of text",
        specificLegislation: "Specific Legislation",
        fisheries: "Related legislation - Fisheries",
        forestry: "Related legislation - Forestry",
        wildSpecies: "Related legislation - Wild species & ecosystems",
        others: "Related legislation - Others",
        biodiversity: "Additional legislation - Biodiversity",
        environment: "Additional legislation -Environment",
        customs: "Additional legislation -Customs / international trade",
        indigenous: "Additional legislation -Indigenous peoples and local communities",
        publicHealth: "Additional legislation -Public health",
        animalHealth: "Additional legislation -Animal health",
        animalProduction: "Additional legislation -Animal production",
        animalWelfare: "Additional legislation -Animal welfare",
        relatedPolicies: "Related policies",
        agreements: "Agreements",
      },
    ];
    documents.forEach((data) => {
      let link = "https://www.fao.org/faolex/results/details/" + language + "/c/" + data.recId;
      // console.log(data, "data dentro XLS")
      filesPdf.push(link);
    });

    documents.forEach((data) => {
      formatXlsJson.push({
        recId: data.recId,
        typeOfText: data.typeOfText,
        title: data.title,
        dateOfText: data.dateOfText,
        specificLegislation: data.specificLegislation ? "X" : "",
        fisheries: data.fisheries ? "X" : "",
        forestry: data.forestry ? "X" : "",
        wildSpecies: data.wildSpecies ? "X" : "",
        others: data.others ? "X" : "",
        biodiversity: data.biodiversity ? "X" : "",
        environment: data.environment ? "X" : "",
        customs: data.customs ? "X" : "",
        indigenous: data.indigenous ? "X" : "",
        publicHealth: data.publicHealth ? "X" : "",
        animalHealth: data.animalHealth ? "X" : "",
        animalProduction: data.animalProduction ? "X" : "",
        animalWelfare: data.animalWelfare ? "X" : "",
        relatedPolicies: data.relatedPolicies ? "X" : "",
        agreements: data.agreements ? "X" : "",
      });
    });

    DownloadXLS(formatXlsJson, filesPdf, "Cites_lex_" + profile["country_" + language]);
  };

  return isLoading ? (
    <div>
      <div id="all-documents">
        <div className="header-wrap">
          <div className="country">
            <div className="img-country">
              <img
                className="country-page-flag"
                width="50px"
                alt=""
                src={`https://www.fao.org/fileadmin/assets/countries/flags/${countryId && countryId.toString().toLowerCase()
                  }.gif`}
              ></img>
            </div>
            <div className="name-country">
              <h3>{profile["country_" + language]}</h3>

            </div>
          </div>
          <div className="results-found">
            <h5>{documents ? documents.length : 0} Results</h5>
          </div>
          <div className="buttons-download">
            <button
              type="submit"
              className="btn btn-primary me-4"
              onClick={() => DownloadPdf()}
            >
              Download PDF
              <FontAwesomeIcon
                icon={faFilePdf}
                color="red"
                data-toggle="tooltip"
                data-placement="left"
                style={{ marginLeft: "5px" }}
              />
            </button>
            <button
              type="submit"
              className="btn btn-primary me-4"
              onClick={() => downloadXls()}
            >
              Download XLS
              <FontAwesomeIcon
                icon={faFileExcel}
                color="#90ee90"
                data-toggle="tooltip"
                data-placement="left"
                style={{ marginLeft: "5px" }}
              />
            </button>
          </div>
        </div>

        <table
          id="table-results"
          className="table"
          style={{ verticalAlign: "middle", fontSize: "13px" }}
        >
          <thead style={{ verticalAlign: "middle" }}>
            <tr>
              <th align="center" rowSpan="2">
                <small>
                  <b>Jurisdiction</b>
                </small>
              </th>
              <th align="center" rowSpan="2">
                <small>
                  <b>Title</b>
                </small>
              </th>
              <th align="center" rowSpan="2">
                <small>
                  <b>Type of text</b>
                </small>
              </th>
              <th align="center" rowSpan="2">
                <small>
                  <b>Date of text</b>
                </small>
              </th>
              <th align="center" rowSpan="2">
                <small>
                  <b>SPECIFIC LEGISLATION</b>
                </small>
              </th>
              <th align="center" colSpan="4">
                <small>
                  <b>RELATED LEGISLATION</b>
                </small>
              </th>
              <th align="center" colSpan="8">
                <small>
                  <b>ADDITIONAL LEGISLATION</b>
                </small>
              </th>
              <th align="center" rowSpan="2">
                <small>
                  <b>RELATED POLICIES</b>
                </small>
              </th>
              <th align="center" rowSpan="2">
                <small>
                  <b>AGREEMENTS</b>
                </small>
              </th>
            </tr>
            <tr>
              <th align="center">
                <small>
                  <b>Fisheries</b>
                </small>
              </th>
              <th align="center">
                <small>
                  <b>Forestry</b>
                </small>
              </th>
              <th align="center">
                <small>
                  <b>Wild species & ecosystems</b>
                </small>
              </th>
              <th align="center">
                <small>
                  <b>Others</b>
                </small>
              </th>
              <th align="center">
                <small>
                  <b>Biodiversity</b>
                </small>
              </th>
              <th align="center">
                <small>
                  <b>Environment</b>
                </small>
              </th>
              <th align="center">
                <small>
                  <b>Customs / international trade</b>
                </small>
              </th>
              <th align="center">
                <small>
                  <b>Indigenous peoples and local communities</b>
                </small>
              </th>
              <th align="center">
                <small>
                  <b>Public health</b>
                </small>
              </th>
              <th align="center">
                <small>
                  <b>Animal health</b>
                </small>
              </th>
              <th align="center">
                <small>
                  <b>Animal production</b>
                </small>
              </th>
              <th align="center">
                <small>
                  <b>Animal welfare</b>
                </small>
              </th>
            </tr>
          </thead>
          {documents
            ? documents.map((document) => (
              <tbody key={document.recId}>
                <tr>
                  <td style={{ width: "100px" }}>{profile["country_" + language]} {document.jurisdiction !== "" ? ("(" + document.jurisdiction + ")") : null}</td>
                  <td style={{ width: "140px" }}><a href={"https://www.fao.org/faolex/results/details/" + language + "/c/" + document.recId}>{document.title}</a></td>
                  <td>{document.typeOfText}</td>
                  <td style={{ width: "100px" }}>{document.dateOfText}</td>
                  <td>
                    {document.specificLegislation ? (
                      <img src={CircleGreen} alt="" />
                    ) : null}
                  </td>
                  <td>
                    {document.fisheries ? (
                      <img src={CircleGreen} alt="" />
                    ) : null}
                  </td>
                  <td>
                    {document.forestry ? (
                      <img src={CircleGreen} alt="" />
                    ) : null}
                  </td>
                  <td>
                    {document.wildSpecies ? (
                      <img src={CircleGreen} alt="" />
                    ) : null}
                  </td>
                  <td>
                    {document.others ? (
                      <img src={CircleGreen} alt="" />
                    ) : null}
                  </td>
                  <td>
                    {document.biodiversity ? (
                      <img src={CircleGreen} alt="" />
                    ) : null}
                  </td>
                  <td>
                    {document.environment ? (
                      <img src={CircleGreen} alt="" />
                    ) : null}
                  </td>
                  <td>
                    {document.customs ? (
                      <img src={CircleGreen} alt="" />
                    ) : null}
                  </td>
                  <td>
                    {document.indigenous ? (
                      <img src={CircleGreen} alt="" />
                    ) : null}
                  </td>
                  <td>
                    {document.publicHealth ? (
                      <img src={CircleGreen} alt="" />
                    ) : null}
                  </td>
                  <td>
                    {document.animalHealth ? (
                      <img src={CircleGreen} alt="" />
                    ) : null}
                  </td>
                  <td>
                    {document.animalProduction ? (
                      <img src={CircleGreen} alt="" />
                    ) : null}
                  </td>
                  <td>
                    {document.animalWelfare ? (
                      <img src={CircleGreen} alt="" />
                    ) : null}
                  </td>
                  <td>
                    {document.relatedPolicies ? (
                      <img src={CircleGreen} alt="" />
                    ) : null}
                  </td>
                  <td>
                    {document.agreements ? (
                      <img src={CircleGreen} alt="" />
                    ) : null}
                  </td>
                </tr>
              </tbody>
            ))
            : null}
        </table>
      </div>
    </div>
  ) : (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <ViewSpinner />
    </div>
  );
}

export default AllDocumentsView;
