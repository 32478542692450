import { useEffect, useState } from "react";
import Config from "../../config";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import "./CountryProfiles.css";
import ViewSpinner from "../../shared/Spinner";
import { Dropdown } from "primereact/dropdown";

function CountryProfiles() {
  const [genderLexCp, setGenderlexCp] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [country, setCountry] = useState([]);
  const [countryLoad, setCountryLoad] = useState([]);
  var location = useLocation()

  let url = window.location.href.toString().split("?")[0];
  let lang = url.substring(url.length - 2);
  if (
    lang !== "fr" &&
    lang !== "es" &&
    lang !== "ru" &&
    lang !== "ar" &&
    lang !== "zh"
  ) {
    lang = "en";
  }
  document.documentElement.setAttribute("lang", lang);

  let countryLang = "country_" + lang;

  var lastLetter = "";

  useEffect(() => {
    setIsLoading(false);
    fetch(Config.apis.countryprofiles)
      .then((response) => response.json())
      .then((data) => {
        setGenderlexCp(data.profiles.sort());
      })
      .then(() => setTimeout(() => setIsLoading(true), 1000));
  }, [countryLang, lang]);

  useEffect(() => {
    fetch(Config.apis.countryprofiles)
      .then((response) => response.json())
      .then((data) => {
        setCountryLoad(data.profiles);
      });
  }, []);

  const changeCountry = (e) => {
    let country = e.value.country_ISO3_Code;
    window.location.href = `/country/profiles/${country}`;
  };

  return (
    <div>
      {isLoading ? (
        <div id="country-profiles-page">
          <header>
            <div className="header-country-profile">
              <div className="menu-container d-flex justify-content-start align-items-center">
                <div className="text-header" style={{ marginLeft: 60 }}>
                  <a className="link-header" href="/">
                    <i class="bi bi-house-door-fill" style={{height: 31, fontWeight: "bold"}}></i>
                  </a>
                </div>
                <div className="text-header ms-4">
                  <a className="link-header" href="/country">
                    Countries
                  </a>
                </div>
                <div className="ms-4">
                  <Dropdown
                    id="country"
                    className="country-multi"
                    value={country}
                    onChange={(e) => changeCountry(e)}
                    options={countryLoad}
                    optionLabel={`country_${lang}`}
                    placeholder="Select a country..."
                    filter
                  />
                </div>
                <div className="ms-4">
                  <Link to="/search" className="text-button">
                    <button className="button-main">
                      <i
                        id="asideToggleIcon"
                        className="bi bi-search inside-icon"
                      ></i>
                      <span className="ms-3">
                        <FormattedMessage id="Navigation.advanced" />
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </header>
          <div className="profiles-country-box">
            {genderLexCp !== undefined && genderLexCp !== null
              ? genderLexCp.map((item) => {
                  return (item.organization_Code === undefined ||
                    item.organization_Code === null) &&
                    (item.territory === undefined ||
                      item.territory === null) ? (
                    <div key={item[countryLang]}>
                      {undefined !== item[countryLang] &&
                      lastLetter !== item[countryLang].charAt(0) ? (
                        <div className="letter-alphabet">
                          <h4
                            style={{ paddingLeft: "5px", marginBottom: "5px" }}
                          >
                            {(lastLetter = item[countryLang].charAt(0))}
                          </h4>
                        </div>
                      ) : null}
                      <div
                        className="country-link"
                        key={item.country_ISO3_Code}
                      >
                        <Link
                          to={`profiles/${item.country_ISO3_Code}`}
                        >
                          <span
                            className={`flag flag-${item.country_ISO3_Code.toLowerCase()} flag-small`}
                          ></span>
                          {item[countryLang]}
                        </Link>
                      </div>
                    </div>
                  ) : null;
                })
              : null}
          </div>
        </div>
      ) : (
        <div className="loading">
          <div className="wrap-spinner">
            {" "}
            <ViewSpinner />{" "}
          </div>
        </div>
      )}
    </div>
  );
}

export default CountryProfiles;
