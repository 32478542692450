import React from "react";
import HeadLanguage from "./HeadLanguage";
import "fao-design-system/fao-theme.min.css";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Config from "../config";

const Header = ({ lang, showBar, show = () => {} }) => {

  return (
    <header id="header">
        {showBar ? (
          <button
            type="button"
            style={{
              backgroundColor: "#1c4767",
              border: "none",
              marginLeft: "5px",
            }}
            onClick={show}
          >
            <FontAwesomeIcon
              className="inside-button"
              icon={faBars}
              color="white"
              data-toggle="tooltip"
              data-placement="left"
              title=""
            />
          </button>
        ) : null}
        <img
          src={`${Config.urls.faolex.image_fao}${lang}.svg`}
          alt=""
          title="FAO home"
          className="image-fao"
          onClick={() => {window.location.href = '/'}}
        ></img>
        <div
          style={{
            border: "1px solid",
            color: " lightgrey",
            marginLeft: "10px",
            marginRight: "15px",
            marginBlock: "1rem",
            height: "50px",
          }}
        ></div>
         <img
          src="https://cites.org/themes/custom/cites/logo.svg"
          alt=""
          title="CITES"
          className="image-cites"
          onClick={() => {window.location.href = Config.urls.faolex.fao_home}}
        ></img>
        <div className="d-flex" style={{ width: "90%" }}>
          <h1 className="header-text">CITES-LEX</h1>
          <div style={{ margin: "auto" }}></div>
        </div>
        <HeadLanguage lang={lang} />
        
    </header>
  );
};

export default Header;
