import { useMemo, useState } from "react";
import { Costants } from "./Costants";

function compare(a, b) {
  if (parseInt(a.value.stringValue) < parseInt(b.value.stringValue)) {
    return -1;
  }
  if (parseInt(a.value.stringValue) > parseInt(b.value.stringValue)) {
    return 1;
  }
  return 0;
}

function Facets({ facet, facetChange }) {
  const [arraySlice, setArraySlice] = useState({
    facetyear: 10,
    countries: 10,
    documentlanguages: 10,
    geographicalareas: 10,
    keywords: 10,
    mainareas: 10,
    organization: 10,
    subjectselections: 10,
    territorialsubdivisions: 10,
    typeoftexts: 10,
    topic: 10,
  });

  const [selectFaces, setSelectFaces] = useState([]);

  useMemo(() => {
    if (facet) {
      var jsonString = "";
      var arrayBuckets = [{ field: "", buckets: [] }];
      for (var i = 0; i < facet.length; i++) {
        for (let el of Costants.facetsMap) {
          if (facet[i].operatorName === el.field) {
            arrayBuckets.push({
              field: facet[i].operatorName,
              buckets: facet[i].buckets,
            });
            jsonString += `{"field": "${facet[i].operatorName}", "priority": ${el.priority}, "buckets": [] }, `;
          }
        }
      }

      var facetmade = JSON.parse(`[${jsonString.slice(0, -2)}]`);

      for (let el of facetmade) {
        for (let bl of arrayBuckets) {
          if (el.field === bl.field) {
            if (el.field === "facetyear") {
              el.buckets.push(bl.buckets.sort(compare).reverse());
            } else {
              el.buckets.push(bl.buckets);
            }
          }
        }
      }
      setSelectFaces(
        facetmade.sort((a, b) =>
          a.priority < b.priority ? -1 : a.priority > b.priority ? 1 : 0
        )
      );
    }
  }, [facet]);

  const sliceChange = (type, op) => {
    switch (type) {
      case "topic":
        setArraySlice({
          topic:
            op === "+"
              ? (arraySlice.topic += 10)
              : op === "-" && arraySlice.topic - 10 >= 10
                ? (arraySlice.topic -= 10)
                : arraySlice.topic,
          facetyear: arraySlice.facetyear,
          countries: arraySlice.countries,
          documentlanguages: arraySlice.documentlanguages,
          geographicalareas: arraySlice.geographicalareas,
          keywords: arraySlice.keywords,
          mainareas: arraySlice.mainareas,
          organization: arraySlice.organization,
          subjectselections: arraySlice.subjectselections,
          territorialsubdivisions: arraySlice.territorialsubdivisions,
          typeoftexts: arraySlice.typeoftexts,
        });
        break;
      case "facetyear":
        setArraySlice({
          topic: arraySlice.topic,
          facetyear:
            op === "+"
              ? (arraySlice.facetyear += 10)
              : op === "-" && arraySlice.facetyear - 10 >= 10
                ? (arraySlice.facetyear -= 10)
                : arraySlice.facetyear,
          countries: arraySlice.countries,
          documentlanguages: arraySlice.documentlanguages,
          geographicalareas: arraySlice.geographicalareas,
          keywords: arraySlice.keywords,
          mainareas: arraySlice.mainareas,
          organization: arraySlice.organization,
          subjectselections: arraySlice.subjectselections,
          territorialsubdivisions: arraySlice.territorialsubdivisions,
          typeoftexts: arraySlice.typeoftexts,
        });
        break;
      case "countries":
        setArraySlice({
          topic: arraySlice.topic,
          facetyear: arraySlice.facetyear,
          countries:
            op === "+"
              ? (arraySlice.countries += 10)
              : op === "-" && arraySlice.countries - 10 >= 10
                ? (arraySlice.countries -= 10)
                : arraySlice.countries,
          documentlanguages: arraySlice.documentlanguages,
          geographicalareas: arraySlice.geographicalareas,
          keywords: arraySlice.keywords,
          mainareas: arraySlice.mainareas,
          organization: arraySlice.organization,
          subjectselections: arraySlice.subjectselections,
          territorialsubdivisions: arraySlice.territorialsubdivisions,
          typeoftexts: arraySlice.typeoftexts,
        });
        break;
      case "documentlanguages":
        setArraySlice({
          topic: arraySlice.topic,
          facetyear: arraySlice.facetyear,
          countries: arraySlice.countries,
          documentlanguages:
            op === "+"
              ? (arraySlice.documentlanguages += 10)
              : op === "-" && arraySlice.documentlanguages - 10 >= 10
                ? (arraySlice.documentlanguages -= 10)
                : arraySlice.documentlanguages,
          geographicalareas: arraySlice.geographicalareas,
          keywords: arraySlice.keywords,
          mainareas: arraySlice.mainareas,
          organization: arraySlice.organization,
          subjectselections: arraySlice.subjectselections,
          territorialsubdivisions: arraySlice.territorialsubdivisions,
          typeoftexts: arraySlice.typeoftexts,
        });
        break;
      case "geographicalareas":
        setArraySlice({
          topic: arraySlice.topic,
          facetyear: arraySlice.facetyear,
          countries: arraySlice.countries,
          documentlanguages: arraySlice.documentlanguages,
          geographicalareas:
            op === "+"
              ? (arraySlice.geographicalareas += 10)
              : op === "-" && arraySlice.geographicalareas - 10 >= 10
                ? (arraySlice.geographicalareas -= 10)
                : arraySlice.geographicalareas,
          keywords: arraySlice.keywords,
          mainareas: arraySlice.mainareas,
          organization: arraySlice.organization,
          subjectselections: arraySlice.subjectselections,
          territorialsubdivisions: arraySlice.territorialsubdivisions,
          typeoftexts: arraySlice.typeoftexts,
        });
        break;
      case "keywords":
        setArraySlice({
          topic: arraySlice.topic,
          facetyear: arraySlice.facetyear,
          countries: arraySlice.countries,
          documentlanguages: arraySlice.documentlanguages,
          geographicalareas: arraySlice.geographicalareas,
          keywords:
            op === "+"
              ? (arraySlice.keywords += 10)
              : op === "-" && arraySlice.keywords - 10 >= 10
                ? (arraySlice.keywords -= 10)
                : arraySlice.keywords,
          mainareas: arraySlice.mainareas,
          organization: arraySlice.organization,
          subjectselections: arraySlice.subjectselections,
          territorialsubdivisions: arraySlice.territorialsubdivisions,
          typeoftexts: arraySlice.typeoftexts,
        });
        break;
      case "mainareas":
        setArraySlice({
          topic: arraySlice.topic,
          facetyear: arraySlice.facetyear,
          countries: arraySlice.countries,
          documentlanguages: arraySlice.documentlanguages,
          geographicalareas: arraySlice.geographicalareas,
          keywords: arraySlice.keywords,
          mainareas:
            op === "+"
              ? (arraySlice.mainareas += 10)
              : op === "-" && arraySlice.mainareas - 10 >= 10
                ? (arraySlice.mainareas -= 10)
                : arraySlice.mainareas,
          organization: arraySlice.organization,
          subjectselections: arraySlice.subjectselections,
          territorialsubdivisions: arraySlice.territorialsubdivisions,
          typeoftexts: arraySlice.typeoftexts,
        });
        break;
      case "organization":
        setArraySlice({
          topic: arraySlice.topic,
          facetyear: arraySlice.facetyear,
          countries: arraySlice.countries,
          documentlanguages: arraySlice.documentlanguages,
          geographicalareas: arraySlice.geographicalareas,
          keywords: arraySlice.keywords,
          mainareas: arraySlice.mainareas,
          organization:
            op === "+"
              ? (arraySlice.organization += 10)
              : op === "-" && arraySlice.organization - 10 >= 10
                ? (arraySlice.organization -= 10)
                : arraySlice.organization,
          subjectselections: arraySlice.subjectselections,
          territorialsubdivisions: arraySlice.territorialsubdivisions,
          typeoftexts: arraySlice.typeoftexts,
        });
        break;
      case "subjectselections":
        setArraySlice({
          topic: arraySlice.topic,
          facetyear: arraySlice.facetyear,
          countries: arraySlice.countries,
          documentlanguages: arraySlice.documentlanguages,
          geographicalareas: arraySlice.geographicalareas,
          keywords: arraySlice.keywords,
          mainareas: arraySlice.mainareas,
          organization: arraySlice.organization,
          subjectselections:
            op === "+"
              ? (arraySlice.subjectselections += 10)
              : op === "-" && arraySlice.subjectselections - 10 >= 10
                ? (arraySlice.subjectselections -= 10)
                : arraySlice.subjectselections,
          territorialsubdivisions: arraySlice.territorialsubdivisions,
          typeoftexts: arraySlice.typeoftexts,
        });
        break;
      case "territorialsubdivisions":
        setArraySlice({
          topic: arraySlice.topic,
          facetyear: arraySlice.facetyear,
          countries: arraySlice.countries,
          documentlanguages: arraySlice.documentlanguages,
          geographicalareas: arraySlice.geographicalareas,
          keywords: arraySlice.keywords,
          mainareas: arraySlice.mainareas,
          organization: arraySlice.organization,
          subjectselections: arraySlice.subjectselections,
          territorialsubdivisions:
            op === "+"
              ? (arraySlice.territorialsubdivisions += 10)
              : op === "-" && arraySlice.territorialsubdivisions - 10 >= 10
                ? (arraySlice.territorialsubdivisions -= 10)
                : arraySlice.territorialsubdivisions,
          typeoftexts: arraySlice.typeoftexts,
        });
        break;
      case "typeoftexts":
        setArraySlice({
          topic: arraySlice.topic,
          facetyear: arraySlice.facetyear,
          countries: arraySlice.countries,
          documentlanguages: arraySlice.documentlanguages,
          geographicalareas: arraySlice.geographicalareas,
          keywords: arraySlice.keywords,
          mainareas: arraySlice.mainareas,
          organization: arraySlice.organization,
          subjectselections: arraySlice.subjectselections,
          territorialsubdivisions: arraySlice.territorialsubdivisions,
          typeoftexts:
            op === "+"
              ? (arraySlice.typeoftexts += 10)
              : op === "-" && arraySlice.typeoftexts - 10 >= 10
                ? (arraySlice.typeoftexts -= 10)
                : arraySlice.typeoftexts,
        });
        break;
      default:
    }
  };

  const facetSel = (facet) => {
    let facetName = "";
    for (let el of Costants.facetsMap) {
      if (el.field === facet) {
        facetName = el.name;
      }
    }

    return facetName;
  };

  return (
    <div>
      {selectFaces ? (
        selectFaces.length !== 0 ? (
          <h5 style={{ marginLeft: "35px", marginBottom: "20px" }}>
            REFINE SEARCH
          </h5>
        ) : null
      ) : null}
      {selectFaces && selectFaces.length !== 0
        ? selectFaces.map((facet) => (
          <div key={facet.field} style={{ marginBottom: "20px" }}>
            <h5>{facetSel(facet.field)}</h5>
            {facet.buckets[0]
              .slice(0, arraySlice[facet.field])
              .map((bucket) => (
                <div key={bucket.value.stringValue}>
                  <button className="button-facets" onClick={() => {
                      facetChange(
                        bucket.value.stringValue,
                        facet.field,
                        "add"
                      );
                    }}
                  >
                    {facet.field === "facetyear"
                      ? `${bucket.value.stringValue} (${bucket.count})`
                      : `${bucket.value.stringValue.split("#")[0] === 'Legislation' ? 'Law' : bucket.value.stringValue.split("#")[0]} (${bucket.count
                      })`}
                  </button>
                </div>
              ))}
            {facet.buckets[0].length > 10 &&
              arraySlice[facet.field] <= facet.buckets[0].length ? (
              <button
                className="button-more-less"
                onClick={() => sliceChange(facet.field, "+")}
              >
                more +
              </button>
            ) : null}
            {arraySlice[facet.field] !== 10 ? (
              <button
                className="button-more-less"
                onClick={() => sliceChange(facet.field, "-")}
              >
                less -
              </button>
            ) : null}
          </div>
        ))
        : null}
    </div>
  );
}

export default Facets;
