import React, { useEffect } from "react";
import Header from "./Header";
import Sidenav from "./Sidenav";
import { useState } from "react";
import { useLocation } from "react-router";
import FooterApplication from "./FooterApplication";
import { Outlet } from "react-router-dom"

const FaoPageContainer = ({ lang }) => {
  const [showNav, setShowNav] = useState(false);
  const [top, setTop] = useState(85);
  const [open, setOpen] = useState(true);
  const location = useLocation();

  if (
    lang !== "fr" &&
    lang !== "es" &&
    lang !== "ru" &&
    lang !== "ar" &&
    lang !== "zh"
  ) {
    lang = "en";
  }
  const languageClassChange =
    lang === "ar" ? "main-arabic" : lang === "ru" ? "main" : "main";

  if (lang === "ar")
    document.documentElement.style.setProperty("direction", "rtl");

  useEffect(() => {
    if (!(location.pathname === "/" || location.pathname === `/${lang}`)) {
      setShowNav(true);
    } else {
      setShowNav(false);
    }
  }, [lang, location.pathname]);

  const openClose = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  // const marginChanger =
  //   lang === "ar" ? { marginRight: margin } : { marginLeft: margin };

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById("header").style.top = "-150px";
      setTop(0);
    } else {
      document.getElementById("header").style.top = "0";
      setTop(85);
    }
  }
  return (
    <div id={languageClassChange}>
      <Header lang={lang} />

      {/* {showNav ? (
        <div
          className={open ? "main-nav-close" : "main-nav-open"}
          style={{ top: top }}
        >
          <Sidenav lang={lang} margin={openClose} open={open} />
        </div>
      ) : null} */}

      <div
        className={
          location.pathname === "/en" || location.pathname === "/"
            ? "main-body"
            : open
            ? "main-body-close"
            : "main-body-open"
        }
      >
        <Outlet />
        <FooterApplication lang={lang} />
      </div>
    </div>
  );
};

export default FaoPageContainer;
