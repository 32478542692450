import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import "./Documents.css";
import Config from "../../config";
import { DownloadPDFSummary } from "../../helper/DownloadPDFSummary";
import { Dropdown } from "primereact/dropdown";

function Documents() {
  const { countryId } = useParams();
  const [profiles, setProfile] = useState([]);
  const [countryLoad, setCountryLoad] = useState([]);
  const [country, setCountry] = useState([]);
  const [section, setSection] = useState([]);
  const [sectionTitle, setSectionTitle] = useState([]);

  const intl = useIntl();

  var language = window.location.pathname.substring(
    window.location.pathname.length - 2
  );

  var url = window.location.href;

  let dateLang = language;
  if (
    dateLang !== "fr" &&
    dateLang !== "es" &&
    dateLang !== "ru" &&
    dateLang !== "ar" &&
    dateLang !== "zh"
  ) {
    dateLang = "en";
  }

  language = dateLang;

  useEffect(() => {
    fetch(Config.apis.countryprofiles)
      .then((response) => response.json())
      .then((data) => {
        setProfile(
          data.profiles.filter((x) => x.country_ISO3_Code === countryId)
        );
        setCountryLoad(data.profiles);
        setCountry(
          data.profiles.filter((x) => x.country_ISO3_Code === countryId)
        );
      });
  }, [countryId]);

  useEffect(() => {
    if (profiles.length > 0) {
      let section = [];
      section.push(profiles[0].section[0]);
      setSection(section);
      setSectionTitle(section[0].SectionTitle_en);
    }
  }, [profiles]);

  useEffect(() => {
    if (profiles.length > 0) {
      localStorage.setItem("profile", JSON.stringify(profiles[0]));
      localStorage.setItem("language", language);
    }
  }, [profiles, language]);

  const DownloadPdfSummary = () => {
    let data = profiles[0];

    console.log(data.section, "data.section");

    let rowInit = [];

    let object = {};

    if (data.section !== null && data.section !== undefined) {
      let section = data.section;

      for (let i = 0; i < section.length; i++) {
        object = {
          text: section[i]["SectionTitle_" + language],
          style: "paragraph",
          alignment: "left",
          fontSize: 18,
          color: "#116AAB",
          marginTop: 25,
        };
        rowInit.push(object);

        if (section[i].subsection && section[i].subsection !== null) {
          let subsection = section[i].subsection;

          if (subsection) {
            for (let y = 0; y < subsection.length; y++) {
              object = {
                text:
                  subsection[y].SubsectionTitle_en === ""
                    ? ""
                    : subsection[y].SubsectionTitle_en,
                style: "paragraph",
                alignment: "left",
                fontSize: 12,
                bold: true,
                color: "#545454",
                marginTop: 12,
              };

              rowInit.push(object);

              if (subsection[y].document && subsection[y].document !== null) {
                let document = subsection[y].document;
                if (document.length > 0) {
                  for (let z = 0; z < document.length; z++) {
                    object = {
                      text: document[z]["name_" + language]
                        ? document[z]["name_" + language]
                        : document[z].Title_of_Text +
                          " " +
                          document[z].Date_of_Text
                        ? document[z].Title_of_Text +
                          " " +
                          document[z].Date_of_Text
                        : "",
                      link: document[z].id
                        ? `${url}/document/${document[z].id}/`
                        : null,
                      style: "paragraph",
                      decoration: document[z].id ? "underline" : null,
                      alignment: "left",
                      fontSize: 12,
                      color: "#545454",
                      marginTop: 6,
                    };

                    rowInit.push(object);

                    if (
                      document[z].Natural_resources &&
                      document[z].Natural_resources.length > 0
                    ) {
                      object = {
                        text: "Natural resources:",
                        style: "paragraph",
                        alignment: "left",
                        fontSize: 12,
                        bold: true,
                        color: "#545454",
                        marginTop: 12,
                      };
                      rowInit.push(object);

                      for (
                        let n = 0;
                        n < document[z].Natural_resources.length;
                        n++
                      ) {
                        object = {
                          text: document[z].Natural_resources[n],
                          style: "paragraph",
                          alignment: "left",
                          fontSize: 12,
                          color: "#545454",
                          marginTop: 6,
                        };
                        rowInit.push(object);
                      }
                    }

                    if (
                      document[z].Environment_climate_biodiversity &&
                      document[z].Environment_climate_biodiversity.length > 0
                    ) {
                      object = {
                        text: "Environment, climate, biodiversity:",
                        style: "paragraph",
                        alignment: "left",
                        fontSize: 12,
                        bold: true,
                        color: "#545454",
                        marginTop: 12,
                      };
                      rowInit.push(object);

                      for (
                        let n = 0;
                        n < document[z].Environment_climate_biodiversity.length;
                        n++
                      ) {
                        object = {
                          text: document[z].Environment_climate_biodiversity[n],
                          style: "paragraph",
                          alignment: "left",
                          fontSize: 12,
                          color: "#545454",
                          marginTop: 6,
                        };
                        rowInit.push(object);
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (section[i].related_websites) {
          let websites = section[i].related_websites;
          for (let i = 0; i < websites.length; i++) {
            object = {
              text: websites[i].name_en,
              link: websites[i].url_en,
              style: "paragraph",
              decoration: "underline",
              alignment: "left",
              fontSize: 12,
              color: "#545454",
              marginTop: 6,
            };
            rowInit.push(object);
          }
        } else {
          continue;
        }
      }
    }

    let country = "";

    if (data.party_to_CITES) {
      country =
        data["country_" + language].toString().toLowerCase() +
        " (Party to CITES)";
    } else {
      country =
        data["country_" + language].toString().toLowerCase() +
        " (Not a Party to CITES)";
    }

    DownloadPDFSummary(rowInit, country);
  };

  const changeCountry = (e) => {
    let country = e.value.country_ISO3_Code;
    window.location.href = `/country/profiles/${country}`;
  };

  const changeSection = (section) => {
    console.log(section);
    if (section) {
      let array_temp = [];
      array_temp.push(section);
      setSection(array_temp);
      setSectionTitle(array_temp[0].SectionTitle_en);
    }
  };

  return profiles !== null
    ? profiles.map((item) => (
        <div id="document-cp" key={item.country_ISO3_Code}>
          <header>
            <div className="header-country-profile">
              <div className="menu-container d-flex justify-content-start align-items-center">
                <div className="text-header" style={{ marginLeft: 60 }}>
                  <a className="link-header" href="/">
                    <i
                      class="bi bi-house-door-fill"
                      style={{ height: 31, fontWeight: "bold" }}
                    ></i>
                  </a>
                </div>
                <div className="text-header ms-4">
                  <a className="link-header" href="/country">
                    Countries
                  </a>
                </div>
                <div className="ms-4">
                  <Dropdown
                    id="country"
                    className="country-multi"
                    value={country}
                    onChange={(e) => changeCountry(e)}
                    options={countryLoad}
                    optionLabel={`country_${language}`}
                    placeholder="Select a country..."
                    filter
                  />
                </div>
                <div className="ms-4">
                  <Link to="/search" className="text-button">
                    <button className="button-main">
                      <i
                        id="asideToggleIcon"
                        className="bi bi-search inside-icon"
                      ></i>
                      <span className="ms-3">
                        <FormattedMessage id="Navigation.advanced" />
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </header>
          <div className="row">
            <div className="col-2 nav-mobile">
              {item.section.map((section) =>
                !section.SectionTitle_en.includes(
                  "Related country profiles"
                ) ? (
                  <div>
                    <div
                      className={`title-doc ${
                        section.SectionTitle_en === sectionTitle
                          ? "highlighted"
                          : ""
                      }`}
                      onClick={(e) => changeSection(section)}
                    >
                      {section.SectionTitle_en}
                    </div>
                  </div>
                ) : null
              )}
            </div>
            <div className="col-10 mt-3 description">
              <div className="row">
                <div className="col-8">
                  <h2 style={{ border: 0, margin: 0 }}>
                    <span
                      className={`flag flag-${item.country_ISO3_Code
                        .toString()
                        .toLowerCase()}`}
                      style={{ borderRadius: 2 }}
                    ></span>
                    {item["country_" + language]}
                    {item.party_to_CITES ? (
                      <span className="party-cites ms-2">(Party to CITES)</span>
                    ) : (
                      <span className="party-cites ms-2">
                        (Not a Party to CITES)
                      </span>
                    )}
                  </h2>
                </div>
                <div className="col-4">
                  <div className="input-viewdoc">
                    <Link
                      to={{
                        pathname: `/country-snapshot/${item.country_ISO3_Code}`,
                        state: section,
                      }}
                      target="_blank"
                      className="links"
                      style={{ textDecoration: "none" }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary btn-icon d-block mb-3"
                        value={intl.formatMessage({
                          id: "Country_profiles.button",
                        })}
                      >
                        <i className="bi bi-file-earmark-text"></i>
                        <FormattedMessage id="Country_profiles.button" />
                      </button>
                    </Link>
                  </div>
                  <div className="input-viewdoc">
                    <button
                      type="submit"
                      className="btn btn-primary btn-icon d-block mb-3"
                      value={intl.formatMessage({
                        id: "Country_profiles.button",
                      })}
                      onClick={() => DownloadPdfSummary()}
                    >
                      <i className="bi bi-file-earmark-pdf"></i>
                      Download PDF
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-9">
                  {section.map((section) => (
                    <div>
                      <h5 className="title-desc">{section.SectionTitle_en}</h5>
                    </div>
                  ))}
                  {section[0]?.subsection?.map((subsection) =>
                    subsection.SubsectionTitle_en !== null &&
                    subsection.SubsectionTitle_en !== undefined &&
                    subsection.SubsectionTitle_en !== "" ? (
                      <div style={{ marginBottom: 20 }}>
                        <h5 className="subsection">
                          {subsection.SubsectionTitle_en}
                        </h5>
                        <div>
                          {subsection.document.length > 0 ? (
                            subsection.document?.map((doc) =>
                              doc.Title_of_Text ? (
                                <div className="mt-2" key={doc.Title_of_Text}>
                                  <Link
                                    to={`https://www.fao.org/faolex/results/details/${language}/c/${doc.id}`}
                                    target="_blank"
                                  >
                                    {doc.Territorial_Subdivision ? (
                                      <span className="me-2">
                                        {" "}
                                        {"[" +
                                          doc.Territorial_Subdivision +
                                          "]"}{" "}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    <i className="bi bi-file-earmark-text me-1"></i>
                                    {doc.Title_of_Text ? doc.Title_of_Text : ""}
                                  </Link>
                                  {doc.Date_of_Text
                                    ? " Date of text: " + doc.Date_of_Text
                                    : ""}
                                  {doc.Date_of_Consolidation
                                    ? " | As last amended: (" +
                                      doc.Date_of_Consolidation +
                                      ")"
                                    : ""}
                                </div>
                              ) : (
                                <div
                                  className="mt-2"
                                  key={doc["name_" + language]}
                                >
                                  {doc.url ? (
                                    <Link
                                      to={`https://${doc.url}`}
                                      target="_blank"
                                    >
                                      <i className="bi bi-file-earmark-text me-1"></i>
                                      {doc["name_" + language]
                                        ? doc["name_" + language]
                                        : ""}
                                    </Link>
                                  ) : (
                                    <div>
                                      <i className="bi bi-file-earmark-text me-1"></i>
                                      {doc["name_" + language]
                                        ? doc["name_" + language]
                                        : ""}
                                    </div>
                                  )}
                                </div>
                              )
                            )
                          ) : (
                            <div>No legislation available</div>
                          )}
                        </div>
                        <hr></hr>
                      </div>
                    ) : subsection.document?.length > 0 ? (
                      <div>
                        {subsection.document?.map((doc) => (
                          <div className="mt-2" key={doc.Title_of_Text}>
                            <Link
                              to={`https://www.fao.org/faolex/results/details/${language}/c/${doc.id}`}
                              target="_blank"
                            >
                              {doc.Territorial_Subdivision ? (
                                <span className="me-2">
                                  {" "}
                                  {"[" + doc.Territorial_Subdivision + "]"}{" "}
                                </span>
                              ) : (
                                ""
                              )}
                              <i className="bi bi-file-earmark-text me-1"></i>
                              {doc.Title_of_Text ? doc.Title_of_Text : ""}
                            </Link>
                            {doc.Date_of_Text
                              ? " Date of text: " + doc.Date_of_Text
                              : ""}
                            {doc.Date_of_Consolidation
                              ? " | As last amended: (" +
                                doc.Date_of_Consolidation +
                                ")"
                              : ""}
                            {doc.Natural_resources &&
                            doc.Natural_resources.length > 0 ? (
                              <div>
                                <hr></hr>
                                <h5 className="mt-3 subsection">
                                  Natural resources:
                                </h5>
                                {doc.Natural_resources.map((resource) => (
                                  <div>{resource}</div>
                                ))}
                                <hr></hr>
                              </div>
                            ) : null}
                            {doc.Environment_climate_biodiversity &&
                            doc.Environment_climate_biodiversity.length > 0 ? (
                              <div>
                                <h5 className="mt-3 subsection">
                                  Environment, climate, biodiversity:
                                </h5>
                                {doc.Environment_climate_biodiversity.map(
                                  (environment) => (
                                    <div>{environment}</div>
                                  )
                                )}
                                <hr></hr>
                              </div>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>No legislation available</div>
                    )
                  )}
                  {section[0]?.related_websites
                    ? section[0].related_websites?.map((website) => (
                        <div className="mb-2">
                          <Link to={`${website.url_en}`} target="_blank">
                            <i className="bi bi-globe me-1"></i>
                            {website.name_en ? website.name_en : ""}
                          </Link>
                        </div>
                      ))
                    : null}
                </div>
                <div className="col-3">
                  <div className="pop-up">
                    {item.section.map((section) =>
                      section.SectionTitle_en.includes(
                        "Related country profiles"
                      ) ? (
                        <div>
                          <div className="title-website">
                            {section.SectionTitle_en}
                          </div>
                          {section.related_websites
                            ? section.related_websites?.map((website) => (
                                <div className="mb-2">
                                  <Link
                                    to={`${website.url_en}`}
                                    target="_blank"
                                  >
                                    <i className="bi bi-globe me-1"></i>
                                    {website.name_en ? website.name_en : ""}
                                  </Link>
                                </div>
                              ))
                            : null}
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))
    : null;
}

export default Documents;
